<template>
  <div class="flex flex-column">
    <div class="sku">
      <div class="title bold ml2 mb1 fs16">填写订单</div>
      <div class="relative px2">
        <div class="my2 spec-zone">
          <div v-for="(specValues, specName, index) in goods.attributeMap" :key="index">
            <div class="mb1 mt2 spec-title">{{ specName }}</div>
            <div class="flex flex-wrap">
              <van-tag
                v-for="(value, j) in specValues"
                :key="j"
                round
                @click="onSelectSpec(specName, value)"
                class="mr2 my1"
                :class="{ invalid: !(validAttrs[specName] && validAttrs[specName][value]) }"
                size="large"
                :color="value === selectedSpec[specName] ? 'rgba(229, 70, 53, 0.15)' : '#EEEEEE'"
                :text-color="value === selectedSpec[specName] ? '#d92e21' : '#333333'"
              >
                {{ value }}
              </van-tag>
            </div>
          </div>
        </div>
      </div>
      <van-divider :style="{ height: '8px', background: '#efefef', margin: '15px 0' }" />
      <div class="title flex ml2 mb1 fs16">
        <div class="bold">填写收货信息</div>
        <div class="color_primary">（以下信息将加密发送）</div>
      </div>
      <div class="spec-zone custom-spec-zone">
        <van-form>
          <van-field
            class="field"
            v-model="form.receiverName"
            label="收货姓名"
            placeholder="请输入收货人姓名"
            size="large"
            center
            :rules="[{ required: true, message: '请输入收货人姓名' }]"
          />
          <van-field
            class="field"
            v-model="form.phone"
            type="tel"
            label="手机号码"
            placeholder="请输入收货手机号"
            size="large"
            center
            :rules="[
              { required: true, message: '请输入手机号码' },
              { pattern: /^1[3-9][0-9]\d{8}$/, message: '手机号码格式错误' }
            ]"
          />
          <van-field
            class="field field-address relative"
            readonly
            clickable
            v-model="form.address"
            label="省市区县"
            autosize
            type="textarea"
            rows="1"
            placeholder="点击选择省市区县"
            size="large"
            center
            :rules="[{ required: true, message: '请选择省市区县' }]"
            @click="areaPopup = true"
          >
            <div slot="right-icon" class="absolute" style="right: 10px; top: 10px">
              <IconFont slot="right-icon" size="12px" icon="iconpageleft" color="#888888"></IconFont>
            </div>
          </van-field>
          <van-field
            class="field"
            v-model="form.addressDetail"
            label="详细地址"
            autosize
            type="textarea"
            rows="1"
            placeholder="如街区、小区门牌等详细信息"
            size="large"
            center
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />
        </van-form>
      </div>
      <van-divider :style="{ height: '8px', background: '#efefef' }" />
      <div class="flex flex-column px2">
        <div class="flex justify-between pb3 bold" style="border-bottom: 1px solid #dddddd">
          <div class="bold fs16">配送方式</div>
          <div class="bold fs16">免费包邮</div>
        </div>
        <div class="mt2 mb1">
          <van-checkbox v-model="anonymity" checked-color="#d92e21">匿名购买</van-checkbox>
        </div>
        <div class="my2 flex items-center justify-center">
          <div class="order-protect">
            <img class="order-protect-img" src="~base/assets/img/orderProtect.png" alt="" />
          </div>
        </div>
      </div>
      <div class="px2 mt3">
        <van-button
          class="bg_primary"
          block
          type="danger"
          size="large"
          native-type="submit"
          loading-text="提交中..."
          @click="onSubmit"
          style="border-radius: 4px"
          >提交订单
        </van-button>
      </div>
    </div>
    <van-popup v-model="areaPopup" position="bottom">
      <area-picker title="选择省市区县" :area-list="areaList" @confirm="areaConfirmFn" @cancel="areaPopup = false" />
    </van-popup>
  </div>
</template>

<script>
import { Toast, ImagePreview } from 'vant'
import { IconFont, AreaPicker } from 'components'

export default {
  name: 'SingleProductPurchaseCard',
  components: { IconFont, AreaPicker },
  props: {
    goods: {
      type: Object,
      default: () => {}
    },

    contactInfo: {
      type: Object,
      default: () => {}
    },

    areaList: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // TODO
      goodCommentRate: 98,
      validAttrs: [],
      selectedSpec: {},
      sku: null,
      form: {},
      areaPopup: false,
      radio: '1',
      anonymity: false
    }
  },

  computed: {
    image() {
      // console.log(this.sku, this.goods)
      if (this.sku && this.sku.goodsImg !== '') {
        return this.sku.goodsImg
      } else if (this.goods.iconUrl && this.goods.iconUrl !== '') {
        return this.goods.iconUrl
      } else {
        return this.goods.bannerImgList && this.goods.bannerImgList[0]
      }
    }
  },

  created() {
    // console.log(this.sku, this.goods)
    if (
      !this.sku ||
      this.sku.goodsId !== this.goods.goodsDetailEx.goodsId // 防止商品详情页跳商品详情页时，此组件不重新加载出现数据不一致
    ) {
      this.sku = this.goods.goodsSpecList[0]
    }

    this.form = this.contactInfo
    this.needFillAddress =
      this.contactInfo && this.contactInfo.addressDetail && this.contactInfo.addressDetail.trim() !== '' ? false : true
    // if (this.goods.goodsSpecList.length > 1) {
    //   this.stepIndex = 0
    // } else {
    //   this.stepIndex = 1
    // }

    this.selectedSpec = {}

    if (this.sku) {
      const skuAttr = JSON.parse(this.sku.goodsAttribute)
      for (const key in this.goods.attributeMap) {
        this.$set(this.selectedSpec, key, skuAttr[key])
      }
    }

    // console.log(this.selectedSpec)

    this.onChangeSpec()
  },

  methods: {
    onClickImage() {
      ImagePreview([this.image])
    },

    onSelectSpec(specName, value) {
      if (!(this.validAttrs[specName] && this.validAttrs[specName][value])) {
        return
      }

      if (this.selectedSpec[specName] === value) {
        this.$set(this.selectedSpec, specName, null)
      } else {
        this.$set(this.selectedSpec, specName, value)
      }

      this.onChangeSpec()
      // console.log(specName, value, this.selectedSpec[specName])
    },

    onChangeSpec() {
      const skuList = this.goods.goodsSpecList

      this.sku = _.find(skuList, (ele) => {
        const attrs = JSON.parse(ele.goodsAttribute)
        for (const key in attrs) {
          if (attrs[key] !== this.selectedSpec[key]) {
            return false
          }
        }
        return true
      })

      this.updateValidAttrs()

      // console.log(sku)
    },

    updateValidAttrs() {
      const attrList = []
      for (const key in this.goods.attributeMap) {
        for (const value of this.goods.attributeMap[key]) {
          attrList.push({ key, value })
        }
      }

      const validAttrs = []
      for (const attr of attrList) {
        const target = Object.assign({}, this.selectedSpec, { [attr.key]: attr.value })
        const valid = _.find(this.goods.goodsSpecList, (ele) => {
          const skuAttr = JSON.parse(ele.goodsAttribute)
          for (const key in target) {
            if (target[key] && skuAttr[key] !== target[key]) {
              return false
            }
          }
          return true
        })
        attr.valid = valid ? true : false

        if (!validAttrs[attr.key]) {
          validAttrs[attr.key] = []
        }
        validAttrs[attr.key][attr.value] = valid ? true : false
      }
      // console.log(this.selectedSpec, attrList, validAttrs)

      this.validAttrs = validAttrs

      // console.log(attrList)
    },

    formatSpec() {
      return _.reduce(
        this.selectedSpec,
        (r, v, k) => {
          if (v) {
            r += `${k}: ${v} `
          }
          return r
        },
        ''
      )
    },

    onSubmit() {
      if (!this.sku) {
        Toast.fail('请先选择规格!')
        return
      }

      this.$emit('submit', {
        sku: this.sku,
        contact: this.form
      })
    },

    areaConfirmFn(values) {
      this.areaPopup = false
      this.areaVlues = values
      this.form.address = values.map((item) => item.name).join(' ')
      this.form.addressProvince = values[0].name
      this.form.addressCity = values[1].name
      this.form.addressArea = values[2].name
      this.form.addressCode = values[2].code
    }
  }
}
</script>

<style lang="less">
.custom-spec-zone {
  .van-cell::after {
    border: none;
  }
  .van-cell {
    padding-left: 0px;
  }
  .van-field__label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  .van-field__control {
    // width: 544px;
    height: 50px;
    background: #eeeeee;
    border-radius: 4px;
    padding-left: 24px;
    box-sizing: border-box;
  }
  .field-address {
    input {
      width: 244px;
      font-size: 16px;
    }
  }
  .van-field__body {
    input::-webkit-input-placeholder {
      color: #999999;
      font-size: 16px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #999999;
      font-size: 16px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #999999;
      font-size: 16px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #999999;
      font-size: 16px;
    }
  }
}
</style>
<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';

.satisfaction {
  background-image: linear-gradient(90deg, #fff8dc, #fffaf9);

  height: 35px;
  width: 100%;

  .main-zone {
    height: 35px;
    padding-left: 20px;
    .tips {
      color: #ce6906;
      font-size: 13px;
    }
  }

  .tringle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    // border-right: 5px solid transparent;
    border-top: 5px solid #ea7809;
  }
}
.invalid {
  background-color: #eeeeee !important;
  color: rgba(51, 51, 51, 0.3) !important;
  text-decoration: line-through;
}
.order-protect {
  width: 60%;
  .rem(height, 45px);
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
