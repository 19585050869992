import { render, staticRenderFns } from "./PurchaseCard.vue?vue&type=template&id=1a0d15ae&scoped=true&"
import script from "./PurchaseCard.vue?vue&type=script&lang=js&"
export * from "./PurchaseCard.vue?vue&type=script&lang=js&"
import style0 from "./PurchaseCard.vue?vue&type=style&index=0&id=1a0d15ae&prod&lang=less&"
import style1 from "./PurchaseCard.vue?vue&type=style&index=1&id=1a0d15ae&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a0d15ae",
  null
  
)

export default component.exports