<template>
  <Picker
    ref="picker"
    show-toolbar
    value-key="name"
    :title="title"
    :columns="displayColumns"
    :loading="loading"
    :readonly="readonly"
    :swipe-duration="swipeDuration"
    :visible-item-count="visibleItemCount"
    :cancel-button-text="cancelButtonText"
    :confirm-button-text="confirmButtonText"
    @change="onChange"
    @confirm="onConfirm"
  />
</template>

<script>
import { Picker } from 'vant'

const PLACEHOLDER_CODE = '110101'

export default {
  components: {
    Picker
  },

  props: {
    cancelButtonText: { type: String, default: '取消' },
    confirmButtonText: { type: String, default: '确定' },
    allowHtml: {
      type: Boolean,
      default: true
    },

    visibleItemCount: {
      type: [Number, String],
      default: 6
    },

    swipeDuration: {
      type: [Number, String],
      default: 1000
    },

    value: { type: String, default: PLACEHOLDER_CODE },
    areaList: {
      type: Object,
      default: () => ({})
    },

    columnsNum: {
      type: [Number, String],
      default: 3
    },

    columnsPlaceholder: {
      type: Array,
      default: () => []
    },

    title: {
      type: String,
      default: '请选择'
    },

    loading: {
      type: Boolean,
      default: false
    },

    readonly: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      code: this.value,
      columns: [{ values: [] }, { values: [] }, { values: [] }]
    }
  },

  computed: {
    province() {
      return this.areaList.province_list || {}
    },

    city() {
      return this.areaList.city_list || {}
    },

    county() {
      return this.areaList.county_list || {}
    },

    displayColumns() {
      return this.columns.slice(0, +this.columnsNum)
    },

    placeholderMap() {
      return {
        province: this.columnsPlaceholder[0] || '',
        city: this.columnsPlaceholder[1] || '',
        county: this.columnsPlaceholder[2] || ''
      }
    }
  },

  watch: {
    value(val) {
      this.code = val
      this.setValues()
    },

    areaList: {
      deep: true,
      handler: 'setValues'
    },

    columnsNum() {
      this.$nextTick(() => {
        this.setValues()
      })
    }
  },

  mounted() {
    this.setValues()
  },

  methods: {
    // get list by code
    getList(type, code) {
      let result = []
      if (type !== 'province' && !code) {
        return result
      }

      const list = this[type]
      result = Object.keys(list).map((listCode) => ({
        code: listCode,
        name: list[listCode]
      }))

      if (code) {
        result = result.filter((item) => item.code.indexOf(code) === 0)
      }

      if (this.placeholderMap[type] && result.length) {
        // set columns placeholder
        let codeFill = ''
        if (type === 'city') {
          codeFill = PLACEHOLDER_CODE.slice(2, 4)
        } else if (type === 'county') {
          codeFill = PLACEHOLDER_CODE.slice(4, 6)
        }

        result.unshift({
          code: `${code}${codeFill}`,
          name: this.placeholderMap[type]
        })
      }

      return result
    },

    // get index by code
    getIndex(type, code) {
      const nextCodeMap = {
        province: '',
        city: code.slice(0, 2),
        county: code.slice(0, 4)
      }
      const compareMap = {
        province: code.slice(0, 2),
        city: code.slice(0, 4),
        county: code
      }
      const list = this.getList(type, nextCodeMap[type])
      const targetIndex = list.findIndex((item) => item.code === compareMap[type])
      return targetIndex
    },

    // parse output columns data
    parseOutputValues(values) {
      return values.map((value, index) => {
        // save undefined value
        if (!value) return value

        value = JSON.parse(JSON.stringify(value))

        if (!value.code || value.name === this.columnsPlaceholder[index]) {
          value.code = ''
          value.name = ''
        }

        return value
      })
    },

    onChange(picker, values, index) {
      this.code = values[index].code
      this.setValues()

      const parsedValues = this.parseOutputValues(picker.getValues())
      this.$emit('change', picker, parsedValues, index)
    },

    onConfirm(values, index) {
      values = this.parseOutputValues(values)
      this.setValues()
      this.$emit('confirm', values, index)
    },

    getDefaultCode() {
      if (this.columnsPlaceholder.length) {
        return PLACEHOLDER_CODE
      }

      const countyCodes = Object.keys(this.county)
      if (countyCodes[0]) {
        return countyCodes[0]
      }

      const cityCodes = Object.keys(this.city)
      if (cityCodes[0]) {
        return cityCodes[0]
      }

      return ''
    },

    setValues() {
      let { code } = this

      if (!code) {
        code = this.getDefaultCode()
      }

      const { picker } = this.$refs
      const province = this.getList('province')
      const city = this.getList('city', code.slice(0, 2))

      if (!picker) {
        return
      }

      picker.setColumnValues(0, province)
      picker.setColumnValues(1, city)

      if (city.length && code.slice(2, 4) === '00') {
        code = city[0].code
      }
      picker.setColumnValues(2, this.getList('county', code.slice(0, 4)))
      picker.setIndexes([this.getIndex('province', code), this.getIndex('city', code), this.getIndex('county', code)])
    },

    getValues() {
      const { picker } = this.$refs
      let getValues = picker ? picker.getValues().filter((value) => !!value) : []
      getValues = this.parseOutputValues(getValues)
      return getValues
    },

    getArea() {
      const values = this.getValues()
      const area = {
        code: '',
        country: '',
        province: '',
        city: '',
        county: ''
      }

      if (!values.length) {
        return area
      }

      const names = values.map((item) => item.name)
      const validValues = values.filter((value) => !!value.code)

      area.code = validValues.length ? validValues[validValues.length - 1].code : ''

      area.province = names[0] || ''
      area.city = names[1] || ''
      area.county = names[2] || ''

      return area
    },

    // @exposed-api
    reset(code) {
      this.code = code || ''
      this.setValues()
    }
  }
}
</script>

<style scoped></style>
