<template>
  <div class="w-screen">
    <div class="w-screen p-[10px] bg-white">
      <div class="">
        <span class="border border-solid border-[#E54635] px-[5px] rounded-[2px] text-[#E54635] text-12">
          {{ goods.goodsDetailEx.costPoints + globalPointName }}
        </span>
      </div>
      <div class="mt-[10px] bg-[#EEF8ED] rounded-[2px]">
        <img src="https://img.bj0yx.com/903991231250432" class="h-[30px]" alt="" />
      </div>
      <div class="mt-[10px]">
        <span class="tag">秒杀</span>
        <span class="text-[16px] text-[#333333]">{{ goods.goodsDetailEx.goodsDetailDesc }}</span>
      </div>
      <div class="flex justify-between text-[12px] text-[#999999] mt-[10px]">
        <div class="flex items-center gap-[5px]">
          <span
            class="px-[5px] py-[1px] text-[#808080] text-12 border border-[#CCCCCC] border-solid rounded-[2px]"
            v-for="(tag, index) in goods.styleTags"
            :key="index"
          >
            {{ tag.text }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { mapState } from 'vuex'
import { Toast } from 'vant'

export default {
  props: {
    goods: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      today: dayjs().format('YYYY-MM-DD'),
      receiveCoupon: false,
      getRedPacketGoods: []
    }
  },

  computed: {
    ...mapState({
      marketingBlockAreaFlag: (state) => state.appState.marketingBlockAreaFlag
    }),

    paySaleDesc() {
      return this.goods.goodsDetailEx.saleDesc.replace('已兑换', '')
    }
  },

  mounted() {
    const goodsId = this.goods.goodsDetailEx.goodsId
    this.getRedPacketGoods = localStorage.getItem('getRedPacketGoods')
      ? JSON.parse(localStorage.getItem('getRedPacketGoods'))
      : []

    const index = this.getRedPacketGoods.findIndex((item) => item === goodsId)
    if (index !== -1) {
      this.receiveCoupon = true
    }
  },

  methods: {
    receiveRedPacket() {
      if (this.receiveCoupon) return
      const goodsId = this.goods.goodsDetailEx.goodsId
      this.getRedPacketGoods = localStorage.getItem('getRedPacketGoods')
        ? JSON.parse(localStorage.getItem('getRedPacketGoods'))
        : []

      const index = this.getRedPacketGoods.findIndex((item) => item === goodsId)
      if (index === -1) {
        this.getRedPacketGoods.push(goodsId)
        localStorage.setItem('getRedPacketGoods', JSON.stringify(this.getRedPacketGoods))
        this.receiveCoupon = true
        Toast({
          message: '已帮您领取购物红包，下单更优惠哦~',
          duration: 2000
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
.tag {
  font-size: 12px;
  color: #e54635;
  border: 1px solid #e54635;
  background: #fff;
  border-radius: 2px;
  padding: 1px 5px;
}

.packet {
  &.receive {
    opacity: 0.6;
  }
}

.red-packet {
  background-image: url('https://img.bj0yx.com/901716272742400');
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
</style>
