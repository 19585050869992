import AddWechatPubThemed from './AddWechatPubThemed'
import GoodsListThemed from './GoodsListThemed'
import VanGoodsListThemed from './VanGoodsListThemed'
import PointNoticeMaskThemed from './PointNoticeMaskThemed'
import PointLeftMask from './PointLeftMask'
import TabFilter from './TabFilter'
import CopyRightThemed from './CopyRightThemed'
import ActivityEntrySectionThemed from './ActivityEntrySectionThemed'
import WechatShareMask from './WechatShareMask'
import QrcodeGuideDialog from './QrcodeGuideDialog'
import SuspendShareThemed from './SuspendShareThemed'
import DealBubbleThemed from './DealBubbleThemed'
import GoodsGalleryThemed from './GoodsGalleryThemed'
import PayOnDeliveryGoodsGalleryThemed from './PayOnDeliveryGoodsGalleryThemed'
import ContactCard from './ContactCard'
import FloatAdThemed from './FloatAdThemed'
import HomeSwipe from './HomeSwipe'
import HomeTileActivity from './HomeTileActivity'
import HomeKingKongThemed from './HomeKingKongThemed'
import FootBarThemed from './FootBarThemed'
import ActivityFootBarThemed from './ActivityFootBarThemed'
import HomeRecommendGoods from './HomeRecommendGoods.vue'
import HomeCapsuleThemed from './HomeCapsuleThemed'
import CouponActionSheet from './CouponActionSheet'
import HomeHeader from './HomeHeader'
import Waterfall from './Waterfall'
import AnnounceBar from './AnnounceBar'
import MorePurchaseCard from './MorePurchaseCard'
import RecommendContent from './RecommendContent'

import brand from 'base/brands'

const componentName = brand.renderBrandElement({ id: 'global.componentName' })?.toLowerCase()

const defaults = ['coupon', 'golds']
const getComponents = (key) => {
  // console.log(defaults.includes)
  if (defaults.includes(componentName)) return require(`./${key}`).default
  const component = require(`./${componentName}/${key}`).default
  return component || require(`./${key}`).default
}

const GoodsWaterfallList = getComponents('GoodsWaterfallList')

const GoodsNormalItem = getComponents('ListItem/GoodsNormalItem')

const PointCenterBanner = getComponents('PointCenterBanner')

const DealRecent = getComponents('DealRecent')

const GuessYourFavorThemed = getComponents('GuessYourFavorThemed')
const SelectPayment = getComponents('SelectPayment')

export {
  AddWechatPubThemed,
  GoodsListThemed,
  VanGoodsListThemed,
  PointNoticeMaskThemed,
  PointLeftMask,
  TabFilter,
  CopyRightThemed,
  ActivityEntrySectionThemed,
  WechatShareMask,
  QrcodeGuideDialog,
  SuspendShareThemed,
  DealBubbleThemed,
  DealRecent,
  GoodsGalleryThemed,
  PayOnDeliveryGoodsGalleryThemed,
  SelectPayment,
  ContactCard,
  FloatAdThemed,
  HomeSwipe,
  HomeTileActivity,
  HomeKingKongThemed,
  FootBarThemed,
  ActivityFootBarThemed,
  HomeRecommendGoods,
  HomeCapsuleThemed,
  CouponActionSheet,
  PointCenterBanner,
  HomeHeader,
  Waterfall,
  GoodsWaterfallList,
  GoodsNormalItem,
  AnnounceBar,
  MorePurchaseCard,
  RecommendContent,
  GuessYourFavorThemed
}
