import a from './config.a'
import b from './config.b'
import base from './config.base'
import duiduoduo from './config.duiduoduo'
import duihaoli from './config.duihaoli'
import jinridui from './config.jinridui'
import points from './config.points'
import coupon from './config.coupon'
import couponPure from './config.couponPure'
import golds from './config.golds'

import Vue from 'vue'

const currentBrand =
  process.env.VUE_APP_BRAND && process.env.VUE_APP_BRAND !== 'undefined' ? process.env.VUE_APP_BRAND : 'base'
const brands = {
  a,
  b,
  base,
  duiduoduo,
  duihaoli,
  jinridui,
  points,
  coupon,
  couponPure,
  golds
}

// 后端配置的主题
let serverlessConfig = {}

/**
 * type: 默认string， 支持['object', 'string', 'image']
 */
const renderBrandElement = ({ id, type = 'string' }) => {
  const serverValue = serverlessConfig[id]
  let value = null

  if (serverValue && serverValue !== '') {
    value = serverValue
  } else {
    value = brands[currentBrand] ? brands[currentBrand][id] : null
  }

  if ((type === 'object' || type === 'boolean') && value) {
    try {
      value = JSON.parse(value)
    } catch (err) {
      // console.log(err)
    }
  }

  const imgRegExp = /\.(png|jpg|gif|jpeg|svg)$/ // 校验图片
  const urlRegExp = /^(http(s?)|):\/\/(.+)$/ // 校验是否为网址
  if (type === 'image' || (value && imgRegExp.test(value))) {
    if (value && urlRegExp.test(value)) {
      return value
    }
    return require(`base/assets/img/${value}`)
  }

  return value
}

const load = (config) => {
  serverlessConfig = config || {}

  // console.log(renderBrandElement({id: 'global.pointName'}))
  Vue.prototype.globalPointName = renderBrandElement({ id: 'global.pointName' })
  Vue.prototype.globalExchangeWord = renderBrandElement({ id: 'global.exchangeWord' })
  Vue.prototype.virtualCoupon = renderBrandElement({ id: 'global.point' })
  // console.log(serverlessConfig)
}

export default {
  renderBrandElement,
  load
}
