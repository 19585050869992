import Vue from 'vue'
import Router from 'vue-router'
import brand from 'base/brands'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import xEnum from 'base/utils/enum'
import store from '@/store'

/**
 * Note: 路由配置项
 *
 * hidden: true                   // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true               // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect           // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'             // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * meta : {
    roles: ['admin','editor']    // 设置该路由进入的权限，支持多个权限叠加
    title: 'title'               // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'             // 设置该路由的图标，对应路径src/icons/svg
    breadcrumb: false            // 如果设置为false，则不会在breadcrumb面包屑中显示
  }
 */

const renderBrandElement = brand.renderBrandElement
const mall = renderBrandElement({ id: 'global.mall' })
const liveTitle = renderBrandElement({ id: 'live.title' })
const componentName = brand.renderBrandElement({ id: 'global.componentName' })

// const pointTabbarName = renderBrandElement({ id: 'primary.tabbarName' })
// const isTabbarWelfare = renderBrandElement({ id: 'global.isTabbarWelfare' })
const primaryComponent = renderBrandElement({ id: 'primary.component' })
let defaultRouterPath = renderBrandElement({ id: 'global.defaultRouterPath' })
if (defaultRouterPath === '/primary') {
  defaultRouterPath = '/primary1'
}

const primaryComponentMap = {
  // FactoryAllowance: () => import('base/views/PointCenter/FactoryAllowance'),
  // PointCenter: () => import('base/views/PointCenter'),
  // JinDouCenter: () => import('base/views/PointCenter/ThemeFour'),
  // CreditCenter: () => import('base/views/PointCenter/ThemeFive'),
  pointCenter: () => import('base/views/PointCenter/ThemeSix')
  // Welfare: () => import('base/views/Welfare'),
}

const componentsNames = ['Coupon', 'golds']

// 公共路由
export const constantRoutes = [
  {
    name: 'goodsProfile',
    path: '/goodsProfile/:goodsId',
    component: () => import('base/views/GoodsProfile'),
    props: (route) => ({
      groupType: Number(route.query.type),
      goodsId: route.params.goodsId,
      activityType: route.query.activityType,
      pageSource:
        route.query.pageSource !== undefined
          ? Number(route.query.pageSource)
          : xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    }),
    hidden: true
  },
  {
    name: '货到付款中转页',
    path: '/payOnDeliveryLoading',
    component: () => import('base/views/GoodsProfile/PayOnDeliveryLoading'),
    hidden: true
  },
  {
    name: 'oldGoodsProfile',
    path: '/goodsProfile',
    component: () => import('base/views/GoodsProfile'),
    props: (route) => ({
      groupType: Number(route.query.type),
      goodsId: route.query.goodsId,
      activityType: route.query.activityType,
      pageSource:
        route.query.pageSource !== undefined
          ? Number(route.query.pageSource)
          : xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    }),
    hidden: true
  },
  {
    path: '/payindex',
    component: () =>
      componentsNames.includes(componentName)
        ? import('base/views/Settlement/Settlement')
        : import(`base/views/Settlement/Settlement${componentName}`),
    props: (route) => ({
      orderId: route.query.orderId,
      activityType: route.query.activityType,
      fromPage: route.query.fromPage
    }),
    hidden: true,
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/ordersuccess',
    name: 'ordersuccess',
    component: () => import('base/views/OrderSuccess'),
    props: (route) => ({
      orderId: route.query.orderId,
      from: route.params.from,
      fromPage: route.query.fromPage,
      unIntercept: route.params.unIntercept
    }),
    hidden: true
  },
  {
    path: '/customService',
    redirect: '/customService1',
    component: () => import('base/views/CustomService'),
    hidden: true
  },
  {
    path: '/customService1',
    name: '客服中心',
    component: () => import('base/views/CustomService'),
    hidden: true,
    meta: {
      title: '客服中心',
      keepAlive: true
    }
  },
  {
    path: '/express',
    component: () => import('base/views/Express'),
    props: (route) => ({
      orderId: route.query.orderId
    }),
    hidden: true
  },
  {
    path: '/comment',
    component: () => import('base/views/Comment'),
    props: (route) => ({
      orderId: route.query.orderId
    }),
    hidden: true
  },
  {
    path: '/refund',
    component: () => import('base/views/Refund'),
    props: (route) => ({
      orderId: route.query.orderId
    }),
    name: '申请退款',
    meta: {
      title: '申请退款',
      keepAlive: false
    }
  },
  {
    path: '/activity/timeLimit',
    name: '限时购活动',
    component: () => import('base/views/ActivityTimeLimit'),
    meta: {
      title: '限时购',
      keepAlive: true
    }
  },
  {
    path: '/activity/returnScore',
    name: '等价全额返活动',
    component: () => import('base/views/ActivityReturnScore'),
    props: (route) => ({
      groupTypeId: route.query.groupTypeId ? Number(route.query.groupTypeId) : undefined
    }),
    meta: {
      title: '等价全额返',
      keepAlive: true
    }
  },
  {
    path: '/activity/brand',
    name: '品牌专场活动',
    component: () => import('base/views/ActivityBrand'),
    props: (route) => ({
      groupTypeId: route.query.groupTypeId ? Number(route.query.groupTypeId) : undefined
    }),
    meta: {
      title: '品牌专场',
      keepAlive: true
    }
  },
  {
    path: '/singleProduct',
    component: () => import('base/views/SingleProductActivity'),
    name: '单品活动',
    meta: {
      title: '单品活动',
      keepAlive: true
    }
  },
  {
    name: 'complain',
    path: '/complain',
    component: () => import('base/views/Complain'),
    meta: {
      title: '提交投诉'
    }
  },
  {
    path: '/protocol',
    component: () => import('base/views/Protocol'),
    name: '隐私协议',
    meta: {
      title: '隐私协议',
      keepAlive: false
    }
  },
  {
    path: '/grantPhone',
    component: () => import('base/views/GrantPhone'),
    name: '授权',
    props: (route) => ({
      phoneUnbound: route.query.phoneUnbound,
      from: route.query.from
    }),
    meta: {
      title: '授权'
    }
  },
  {
    name: 'goodsSearch',
    path: '/goodsSearch',
    component: () => import('base/views/GoodsSearch'),
    meta: {
      title: '热搜爆品',
      keepAlive: true
    }
  },

  {
    name: 'videoLiveList',
    path: '/live/list',
    component: () => import('base/views/VideoLiveList'),
    meta: {
      title: `${liveTitle}`
    }
  },
  {
    name: 'videoLiveDetail',
    path: '/live/detail/:videoId',
    props: (route) => ({
      videoId: Number(route.params.videoId)
    }),
    component: () => import('base/views/VideoLiveDetail'),
    meta: {
      title: '直播详情'
    }
  },
  {
    path: '/cooperation',
    component: () => import('base/views/Cooperation'),
    name: '商务合作',
    meta: {
      title: '商务合作'
    }
  },
  {
    path: '/payOnDeliverySuccess',
    component: () => import('base/views/PayOnDeliverySuccess'),
    name: '到付订单成功',
    props: (route) => ({
      orderId: route.query.orderId,
      goodsId: route.query.goodsId,
      groupType: JSON.parse(route.query.groupType),
      pageSource: JSON.parse(route.query.pageSource)
    }),
    meta: {
      title: '交易成功'
    }
  },
  {
    path: '/privateGuide',
    component: () => import('base/views/PrivateGuide'),
    name: '私域导流',
    props: (route) => ({
      codeImageUrl: route.query.codeImageUrl,
      weChat: route.query.weChat
    }),
    meta: {
      title: `${mall}`
    }
  },
  {
    path: '/afterSaleDetail',
    name: '退款详情',
    component: () => import('base/views/AfterSaleDetail'),
    props: (route) => ({
      orderId: route.query.orderId,
      from: route.params.from,
      unIntercept: route.params.unIntercept
    }),
    hidden: true
  },
  {
    path: '/applyRefund',
    name: '申请退款',
    component: () => import('base/views/ApplyRefund'),
    props: (route) => ({
      orderId: route.query.orderId,
      from: route.params.from,
      unIntercept: route.params.unIntercept
    }),
    hidden: true
  },
  {
    path: '/redPacket',
    component: () => import('base/views/RedPacket'),
    name: '挽留红包',
    meta: {
      title: '挽留红包'
    }
  },
  {
    path: '/serviceOrdersDetail',
    name: '服务单详情',
    component: () => import('base/views/ServiceOrdersDetail'),
    props: (route) => ({
      orderId: route.query.orderId
    })
  },
  {
    path: '/fillRefundNumber',
    name: '填写退换货单号',
    component: () => import('base/views/FillRefundNumber'),
    props: (route) => ({
      orderId: route.query.orderId
    })
  },
  {
    path: '/copyPromotionArticle',
    name: '微信浏览器推文页',
    component: () => import('base/views/CopyPromotionArticle')
  },
  {
    path: '/redPacketCoupon',
    component: () => import('base/views/RedPacketCoupon'),
    name: '挽留红包优惠券',
    meta: {
      title: '挽留红包优惠券'
    }
  },
  {
    path: '/businessQualification',
    name: '营业资质',
    component: () => import('base/views/BusinessQualification'),
    meta: {
      title: '营业资质'
    }
  },
  {
    path: '/pointCustom',
    name: '首次返回挽留特殊页',
    component: () => import('base/views/GoBackToCustom')
  },
  {
    path: '/payOnDeliveryHome',
    name: '0元免费领',
    component: () => import('base/views/PayOnDeliveryHome'),
    props: (route) => ({
      goodsGroupType: Number(route.query.type || xEnum.ENUM_GOODS_GROUP_TYPE.PAY_ON_DELIVERY_DEFAULT.value)
    }),
    meta: {
      title: '0元免费领'
    }
  },
  {
    path: '/payOnDeliveryContact',
    name: '',
    component: () => import('base/views/PayOnDeliveryContact'),
    meta: {
      title: ''
    }
  },
  {
    path: '/card',
    component: () => import('base/views/Card/index'),
    name: '购物补贴金',
    meta: {
      title: '购物补贴金',
      keepAlive: true
    }
  },
  {
    path: '/relay',
    component: () => import('base/views/relay'),
    name: '购物补贴金',
    meta: {
      title: '购物补贴金',
      keepAlive: true
    }
  },
  {
    path: '',
    component: Layout,
    redirect: defaultRouterPath,
    children: [
      {
        path: 'index-new',
        redirect: 'primary1',
        component: primaryComponentMap[primaryComponent],
        name: '核心活动',
        meta: {
          title: `${mall}`,
          keepAlive: false
        }
      },
      {
        path: 'primary',
        redirect: 'primary1',
        component: primaryComponentMap[primaryComponent],
        name: '核心活动',
        meta: {
          title: `${mall}`,
          keepAlive: false
        }
      },
      {
        path: 'primary1',
        component: primaryComponentMap[primaryComponent],
        name: '核心活动',
        meta: {
          title: `${mall}`,
          keepAlive: true
        }
      },
      {
        path: 'centerPage',
        redirect: 'centerPageA',
        component: () => import('base/views/PointCenter/centerPageA'),
        name: '卡片中间页A',
        meta: {
          title: `${mall}`,
          keepAlive: true
        }
      },
      {
        path: 'centerPageA',
        component: () => import('base/views/PointCenter/centerPageA'),
        name: '卡片中间页A',
        meta: {
          title: `${mall}`,
          keepAlive: true
        }
      },
      {
        path: 'centerPageB',
        component: () => import('base/views/PointCenter/centerPageB'),
        name: '卡片中间页B',
        meta: {
          title: `${mall}`,
          keepAlive: true
        }
      },
      {
        path: 'leftFirst',
        component: () => import('base/views/RushBuyRank'),
        name: 'leftFirst',
        meta: {
          title: `${mall}`,
          keepAlive: true
        }
      },
      {
        path: 'homePage',
        redirect: 'home',
        component: () => import('base/views/Home/home'),
        name: '首页',
        meta: {
          title: '首页',
          keepAlive: true
        }
      },
      {
        path: 'home',
        component: () => import('base/views/Home/home'),
        name: '首页',
        meta: {
          title: `${mall}`,
          keepAlive: true
        }
      },
      {
        path: 'myorder',
        redirect: 'myorder3',
        component: () => import('base/views/MyOrder/index'),
        name: '我的订单',
        props: (route) => ({
          orderStatus:
            route.query.orderStatus !== undefined
              ? Number(route.query.orderStatus)
              : Number(xEnum.ENUM_ORDER_STATUS.ALL.getValue()),
          from: route.query.from
        }),
        meta: {
          title: '我的订单',
          keepAlive: true
        }
      },
      {
        path: 'myorder1',
        redirect: 'myorder3',
        component: () => import('base/views/MyOrder/index'),
        name: '我的订单',
        props: (route) => ({
          orderStatus:
            route.query.orderStatus !== undefined
              ? Number(route.query.orderStatus)
              : Number(xEnum.ENUM_ORDER_STATUS.ALL.getValue()),
          from: route.query.from
        }),
        meta: {
          title: '我的订单',
          keepAlive: true
        }
      },
      {
        path: 'myorder2',
        redirect: 'myorder3',
        component: () => import('base/views/MyOrder/index'),
        name: '我的订单',
        props: (route) => ({
          orderStatus:
            route.query.orderStatus !== undefined
              ? Number(route.query.orderStatus)
              : Number(xEnum.ENUM_ORDER_STATUS.ALL.getValue()),
          from: route.query.from
        }),
        meta: {
          title: '我的订单',
          keepAlive: true
        }
      },
      {
        path: 'myorder3',
        component: () => import('base/views/MyOrder/index'),
        name: '我的订单',
        props: (route) => ({
          orderStatus:
            route.query.orderStatus !== undefined
              ? Number(route.query.orderStatus)
              : Number(xEnum.ENUM_ORDER_STATUS.ALL.getValue()),
          from: route.query.from
        }),
        meta: {
          title: '我的订单',
          keepAlive: true
        }
      },
      {
        path: 'mine',
        component: () => import('base/views/Mine/mine'),
        name: '个人中心',
        meta: {
          title: '个人中心',
          keepAlive: true
        }
      },
      {
        path: '404',
        component: () => import('base/views/404'),
        name: '404',
        meta: {
          title: '活动已结束',
          keepAlive: false
        }
      }
    ]
  }
]

const myRouter = new Router({
  mode: 'history', // 去掉url中的#
  routes: constantRoutes,
  base: '/vantmall/',
  scrollBehavior(to, from, savedPosition) {
    if (
      savedPosition ||
      ((from.name === 'goodsProfile' ||
        from.name === '品牌专场活动' ||
        from.name === '等价全额返活动' ||
        from.name === '限时购活动' ||
        from.name === '我的订单' ||
        from.name === '个人中心' ||
        from.name === '首页' ||
        from.name === '核心活动' ||
        from.name === 'leftFirst') &&
        (to.name === '核心活动' ||
          to.name === 'leftFirst' ||
          to.name === '个人中心' ||
          to.name === '首页' ||
          to.name === '我的订单' ||
          to.name === '品牌专场活动' ||
          to.name === '等价全额返活动' ||
          to.name === '限时购活动' ||
          to.name === '客服中心' ||
          to.name === 'goodsSearch' ||
          to.name === '单品活动' ||
          to.name === '卡片中间页'))
    ) {
      // if (savedPosition.y == 0) {
      //   savedPosition.y = to.meta.savedPosition || 0
      // }
      // return savedPosition

      if (savedPosition?.y == 0) {
        savedPosition.y = to.meta.savedPosition || 0
        return savedPosition
      } else if (savedPosition) {
        return savedPosition
      } else if (from.meta.keepAlive && to.name !== '品牌专场活动') {
        return { x: 0, y: to.meta.savedPosition || 0 }
      } else {
        return { x: 0, y: 0 }
      }
    } else {
      return { x: 0, y: 0 }
    }
  }
})
myRouter.beforeEach((to, from, next) => {
  if (!store) return
  store.commit('CLEAR_EXPOSURE_DOM_SET')
  next()
})

export default myRouter
