<template>
  <div class="flex flex-column purchase-card">
    <div class="sku">
      <div class="spec-zone custom-spec-zone-cosmetic">
        <van-form>
          <div v-if="showProduct" class="spec-zone px2 flex gap-[13px]">
            <div class="flex text-[#333] text-[14px]"><span class="text-[#FF6600]">*</span>产品选择</div>
            <div class="flex-1" v-for="(specValues, specName, index) in goods.attributeMap" :key="index">
              <div class="flex flex-col gap-[5px] w-full">
                <van-tag
                  v-for="(value, j) in specValues"
                  :key="j"
                  @click="onSelectSpec(specName, value)"
                  class="py-[9px] flex justify-center text-[14px] border border-solid"
                  :class="{
                    invalid: !(validAttrs[specName] && validAttrs[specName][value])
                  }"
                  :style="{ borderColor: value === selectedSpec[specName] ? '#EE3300' : '#cccccc' }"
                  size="large"
                  :color="value === selectedSpec[specName] ? '#EE3300' : '#ffffff'"
                  :text-color="value === selectedSpec[specName] ? '#ffffff' : '#333333'"
                >
                  {{ value }}
                </van-tag>
              </div>
            </div>
          </div>
          <van-field
            class="field"
            v-model="form.receiverName"
            label="您的姓名"
            placeholder="请输入收货人姓名"
            size="large"
            center
            required
            label-class="text-[14px] text-[#333]"
            :rules="[{ required: true, message: '请输入收货人姓名' }]"
          />
          <van-field
            class="field"
            v-model="form.phone"
            type="tel"
            label="手机号码"
            placeholder="请输入收货手机号"
            size="large"
            center
            required
            label-class="text-[14px] text-[#333]"
            :rules="[
              { required: true, message: '请输入手机号码' },
              { pattern: /^1[3-9][0-9]\d{8}$/, message: '手机号码格式错误' }
            ]"
          />
          <div class="flex pt-[15px] pr-[16px] pl-[7px] items-center gap-[15px]">
            <div class="flex text-[#333] text-[14px]"><span class="text-[#FF6600]">*</span>省市区县</div>
            <div class="grid grid-cols-3 gap-[10px] flex-1 justify-end" @click="areaPopup = true">
              <div
                class="flex items-center gap-[5px] border border-solid border-[#ccc] rounded-[2px] px-[5px] py-[7px] whitespace-nowrap"
              >
                <span>{{ form.addressProvince || '请选省份' }}</span>
                <IconFont size="12px" icon="icondown" color="#333333"></IconFont>
              </div>
              <div
                class="flex items-center gap-[5px] border border-solid border-[#ccc] rounded-[2px] px-[5px] py-[7px] whitespace-nowrap"
              >
                <span>{{ form.addressCity || '请选城市' }}</span>
                <IconFont size="12px" icon="icondown" color="#333333"></IconFont>
              </div>
              <div
                class="flex items-center gap-[5px] border border-solid border-[#ccc] rounded-[2px] px-[5px] py-[7px] whitespace-nowrap"
              >
                <span>{{ form.addressArea || '请选地区' }}</span>
                <IconFont size="12px" icon="icondown" color="#333333"></IconFont>
              </div>
            </div>
          </div>
          <van-field
            class="field"
            v-model="form.addressDetail"
            label="详细地址"
            placeholder="如街区、小区门牌等详细信息"
            size="large"
            center
            required
            label-class="text-[14px] text-[#333]"
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />
        </van-form>
      </div>
      <div class="px2 pt-[10px] pb-[15px]">
        <van-button
          class="bg-[#EE3300] text-[21px] py-[14px]"
          block
          type="danger"
          size="large"
          native-type="submit"
          :loading="isOrderFormLoading"
          loading-text="提交中..."
          @click="onSubmit"
          style="border-radius: 4px"
          >立即免费领取
        </van-button>
        <div class="text-[#EE3300] text-[16px] font-bold mt-[6px]">*每个用户只能提交一次，切勿重复提交!</div>
        <div class="flex items-center gap-[3px] mt-[6px] text-[14px]">
          <van-radio name="1" v-model="radio" icon-size="14px" checked-color="#d92e21" />
          <div class="text-[12px]">
            提交已视为阅读并同意
            <span class="text-[#EE3300]" @click="infoSelfProtectShow = true">《个人信息保护》</span>
            <span class="text-[#EE3300]" @click="ruleShow = true">《活动规则》</span>
          </div>
        </div>
      </div>
    </div>
    <div class="box w-full h-[260px] overflow-hidden">
      <div
        v-for="(item, index) in 4"
        :key="index"
        class="item-box"
        :style="{ transform: 'translate(0,' + scrollTop + 'px)' }"
      >
        <div
          class="item flex flex-col gap-[5px] p-[10px] h-[65px]"
          v-for="(_item, _index) in dealRecentList"
          :key="_index"
        >
          <div class="flex justify-between">
            <span class="text-[14px] text-[#333]">{{ _item.name }}</span>
            <span class="text-[14px] text-[#fff] bg-[#EE3300] rounded-[4px] px-[4px] py-[2px]">{{ _item.prov }}</span>
          </div>
          <div class="flex justify-between">
            <span class="text-[14px] text-[#333]">{{ _item.specName }}</span>
            <span class="text-[14px] text-[#999999]">{{ _item.time }}</span>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="areaPopup" position="bottom">
      <area-picker title="选择省市区县" :area-list="areaList" @confirm="areaConfirmFn" @cancel="areaPopup = false" />
    </van-popup>
    <van-popup v-model="ruleShow" position="center" round>
      <div class="w-[80vw] flex flex-col items-center relative px-[20px] py-[40px]">
        <div class="text-[18px] text-[#333333] absolute top-[10px]">活动规则</div>
        <div class="text-[#4d4d4d] text-[14px] py-[20px] max-h-[50vh] overflow-y-scroll" v-html="activityRules"></div>
        <div class="text-[#F10A24] absolute bottom-[10px]" @click="ruleShow = false">我知道了</div>
      </div>
    </van-popup>
    <van-popup v-model="infoSelfProtectShow" position="center" round>
      <div class="w-[80vw] flex flex-col items-center relative px-[20px] py-[40px]">
        <div class="text-[18px] text-[#333333] absolute top-[10px]">个人信息保护</div>
        <div class="text-[#4d4d4d] text-[14px] py-[20px] max-h-[50vh] overflow-y-scroll" v-html="infoSelfProtect"></div>
        <div class="text-[#F10A24] absolute bottom-[10px]" @click="infoSelfProtectShow = false">我知道了</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast, ImagePreview } from 'vant'
import { IconFont, AreaPicker } from 'components'
import mock from 'base/utils/mock'
import areaList from 'base/utils/area'

export default {
  name: 'PurchaseCard',
  components: { IconFont, AreaPicker },
  props: {
    goods: {
      type: Object,
      default: () => {}
    },

    contactInfo: {
      type: Object,
      default: () => {}
    },

    isOrderFormLoading: {
      type: Boolean,
      default: false
    },

    showProduct: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      // TODO
      goodCommentRate: 98,
      validAttrs: [],
      selectedSpec: {},
      sku: null,
      form: {},
      areaPopup: false,
      areaList: areaList,
      radio: '1',
      anonymity: true,
      scrollTop: 0,
      dealRecentList: [],
      ruleShow: false,
      infoSelfProtectShow: false
    }
  },

  computed: {
    image() {
      // console.log(this.sku, this.goods)
      if (this.sku && this.sku.goodsImg !== '') {
        return this.sku.goodsImg
      } else if (this.goods.iconUrl && this.goods.iconUrl !== '') {
        return this.goods.iconUrl
      } else {
        return this.goods.bannerImgList && this.goods.bannerImgList[0]
      }
    },

    activityRules() {
      return this.renderBrandElement({ id: 'global.activityRules' })
    },

    infoSelfProtect() {
      return this.renderBrandElement({ id: 'infoself.protect' })
    }
  },

  created() {
    if (
      !this.sku ||
      this.sku.goodsId !== this.goods.goodsDetailEx.goodsId // 防止商品详情页跳商品详情页时，此组件不重新加载出现数据不一致
    ) {
      this.sku = this.goods.goodsSpecList[0]
    }

    this.form = this.contactInfo
    this.needFillAddress =
      this.contactInfo && this.contactInfo.addressDetail && this.contactInfo.addressDetail.trim() !== '' ? false : true

    this.selectedSpec = {}

    if (this.sku) {
      const skuAttr = JSON.parse(this.sku.goodsAttribute)
      for (const key in this.goods.attributeMap) {
        this.$set(this.selectedSpec, key, skuAttr[key])
      }
    }
    this.onChangeSpec()
    const specList = this.goods.goodsSpecList
    const provinceList = Object.values(areaList.province_list)
    mock.familyNames.forEach(() => {
      this.dealRecentList.push({
        name: `${mock.familyNames[_.random(mock.familyNames.length - 1)]}** [${
          mock.phoneNumbersStart[_.random(mock.phoneNumbersStart.length - 1)]
        }****${_.random(1000, 9999)}]`,
        specName: specList[_.random(specList.length - 1)].goodsSpecName,
        prov: provinceList[_.random(provinceList.length - 1)].replace('省', ''),
        time: `${_.random(1, 60)}分钟前`
      })
    })

    this.roll()
  },

  methods: {
    onClickImage() {
      ImagePreview([this.image])
    },

    onSelectSpec(specName, value) {
      if (!(this.validAttrs[specName] && this.validAttrs[specName][value])) {
        return
      }

      this.$set(this.selectedSpec, specName, value)

      this.onChangeSpec()
      // console.log(specName, value, this.selectedSpec[specName])
    },

    onChangeSpec() {
      const skuList = this.goods.goodsSpecList

      this.sku = _.find(skuList, (ele) => {
        const attrs = JSON.parse(ele.goodsAttribute)
        for (const key in attrs) {
          if (attrs[key] !== this.selectedSpec[key]) {
            return false
          }
        }
        return true
      })

      this.updateValidAttrs()

      // console.log(this.sku)
    },

    updateValidAttrs() {
      const attrList = []
      for (const key in this.goods.attributeMap) {
        for (const value of this.goods.attributeMap[key]) {
          attrList.push({ key, value })
        }
      }

      const validAttrs = []
      for (const attr of attrList) {
        const target = Object.assign({}, this.selectedSpec, { [attr.key]: attr.value })
        const valid = _.find(this.goods.goodsSpecList, (ele) => {
          const skuAttr = JSON.parse(ele.goodsAttribute)
          for (const key in target) {
            if (target[key] && skuAttr[key] !== target[key]) {
              return false
            }
          }
          return true
        })
        attr.valid = valid ? true : false

        if (!validAttrs[attr.key]) {
          validAttrs[attr.key] = []
        }
        validAttrs[attr.key][attr.value] = valid ? true : false
      }
      // console.log(this.selectedSpec, attrList, validAttrs)

      this.validAttrs = validAttrs

      // console.log(attrList)
    },

    formatSpec() {
      return _.reduce(
        this.selectedSpec,
        (r, v, k) => {
          if (v) {
            r += `${k}: ${v} `
          }
          return r
        },
        ''
      )
    },

    onSubmit() {
      if (!this.sku) {
        Toast.fail('请先选择规格!')
        return
      }

      this.$emit('submit', {
        sku: this.sku,
        contact: this.form
      })
    },

    areaConfirmFn(values) {
      this.areaPopup = false
      this.areaVlues = values
      this.form.address = values.map((item) => item.name).join(' ')
      this.form.addressProvince = values[0].name
      this.form.addressCity = values[1].name
      this.form.addressArea = values[2].name
      this.form.addressCode = values[2].code
    },

    toInfoSelfProtect() {
      this.$router.push({ path: '/infoSelfProtect' })
    },

    toRules() {
      this.$router.push({ path: '/payOnDeliveryCosmeticRules' })
    },

    roll() {
      if (this.scrollTop === -1690) {
        this.scrollTop = 0
      }
      this.scrollTop -= 1
      setTimeout(() => {
        this.roll()
      }, 50)
    }
  }
}
</script>

<style lang="less">
.custom-spec-zone-cosmetic {
  .van-cell::after {
    border: none;
  }
  .van-cell {
    padding-left: 0px;
  }
  .van-field__label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  .van-field__control {
    // width: 544px;
    height: 36px !important;
    line-height: 36px !important;
    background: #fff !important;
    border-radius: 2px;
    padding-left: 5px;
    box-sizing: border-box;
    border: 1px solid #ccc;
  }
  .note {
    .van-field__control {
      height: unset !important;
    }
    .van-field__label {
      align-items: flex-start;
      color: #333;
    }
  }
  .field-address {
    input {
      width: 244px;
      font-size: 14px;
    }
  }
  .van-field__body {
    input::-webkit-input-placeholder {
      color: #999999;
      font-size: 14px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #999999;
      font-size: 14px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #999999;
      font-size: 14px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #999999;
      font-size: 14px;
    }
  }
}
</style>
<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';

.satisfaction {
  background-image: linear-gradient(90deg, #fff8dc, #fffaf9);

  height: 35px;
  width: 100%;

  .main-zone {
    height: 35px;
    padding-left: 10px;
    .tips {
      color: #ce6906;
      font-size: 13px;
    }
  }

  .tringle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    // border-right: 5px solid transparent;
    border-top: 5px solid #ea7809;
  }
}
.invalid {
  background-color: #eeeeee !important;
  color: rgba(51, 51, 51, 0.3) !important;
  text-decoration: line-through;
}
.order-protect {
  width: 60%;
  .rem(height, 45px);
  img {
    width: 100%;
    height: 100%;
  }
}
// .spec-desc {
//   word-break: break-all;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3; /* 这里是超出几行省略 */
//   overflow: hidden;
// }

// .box {
//   width: 320px;
//   height: 300px;
//   background-color: pink;
//   overflow: hidden;
// }
.item-box .item {
  border-bottom: 1px solid #ccc;
}
.item-box .item:nth-child(odd) {
  background: #f4f4f4;
}
.item-box .item:nth-child(even) {
  background: #ffffff;
}
// .box .item-box .item {
//   width: 29%;
//   height: 29%;
//   margin: 1%;
//   background-color: paleturquoise;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: 700;
// }
</style>
