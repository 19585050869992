<template>
  <div
    class="time-limit-item flex p2 br10 mb2"
    @click="onClick"
    v-track:exposure
    :track-params="JSON.stringify(getEventInfo(source, index))"
  >
    <van-image class="left-part flex-none" :src="source.imgUrl" :lazy-load="true">
      <template v-slot:loading>
        <van-loading type="spinner" />
      </template>
    </van-image>
    <div v-if="source.stockNum <= 0" class="stock-empty">
      <IconFont size="103px" icon="iconyiqiangwan" color="#AAAAAA"></IconFont>
    </div>
    <div class="right-part flex-auto ml2">
      <div class="text-ellipsis fs14 bold mb1">{{ source.goodsName }}</div>
      <div class="price">
        <div class="absolute left-0 color_tips" style="top: 5px">{{ Number(source.originalPrice).toFixed(2) }}元</div>
        <div class="absolute right-0 color_tips" style="top: 5px">{{ Number(source.originalPrice).toFixed(2) }}元</div>
        <div class="absolute left-0 color_tips" style="top: 65%">日常价</div>
        <div class="absolute right-0 color_tips" style="top: 65%">日常价</div>
        <img class="fit" src="~base/assets/img/timeLimit/linepoint@2x.png" />
        <img
          class="special-price absolute left-0 right-0 animate__animated animate__infinite"
          src="~base/assets/img/timeLimit/superdeal@2x.png"
        />
      </div>
      <div class="bottom-part flex justify-end mt1">
        <div class="flex items-center">
          <div class="color_text fs12" style="margin-top: 3px">到手价</div>
          <div class="buy-price ml1 bold color_price fs18"><span class="fs14">￥</span>{{ Number(source.price) }}</div>
        </div>
        <div
          class="buy-btn flex items-center px2 py1 white animate__animated animate__infinite animate__pulse animate__fast"
          :class="{ sellout: source.stockNum <= 0 }"
        >
          {{ Number(source.stockNum) > 0 ? '立即购买' : '等待下场' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { trackUserEvent } from 'base/utils/rp'
import { IconFont } from 'components'
import { mapState } from 'vuex'
import { Dialog } from 'vant'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'GoodsTimeLimitItem',
  components: { IconFont },
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: () => 0
    },

    type: {
      type: Number,
      default: () => 1
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId
    })
  },

  methods: {
    onClick() {
      const { source, index } = this

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        score: source?.score,
        goodsIndex: index,
        goodsGroupId: this.type,
        webUrl: window.location.href
      }
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_TIME_LIMIT_LIST_CLICK.value,
        eventInfo
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source?.score,
          goods_group_type: this.type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: index,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '限时秒杀',
          goods_category_id: source.categoryId
        }
      })

      if (source.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，请等待下场',
          confirmButtonText: '好的'
        })
        return
      }

      this.$router.push({
        path: `/goodsProfile/${source.id}`,
        query: {
          type: this.type,
          activityType: 'timeLimit'
        }
      })

      this.onClickItem(source, index)
    },

    getEventInfo(goods, index) {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_TIME_LIMIT_LIST_EP.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: goods.id,
          score: goods?.score,
          goodsIndex: index,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          goodsPrice: goods.price,
          goodsName: goods.goodsName,
          goodsCategoryId: goods.categoryId
        }
      }
    }
  }
}
</script>

<style lang="less">
.time-limit-item {
  .left-part {
    img {
      border-radius: 6px;
    }
  }
}
</style>
<style lang="less" scoped>
@-webkit-keyframes mySlideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }
}
@keyframes mySlideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 20%, 0);
    transform: translate3d(0, 20%, 0);
  }
}
.time-limit-item {
  position: relative;
  font-size: 14px;
  background-color: #fff;
  border-bottom: 1px solid #ededed;
  .left-part {
    width: 120px;
    height: 120px;
  }
  .right-part {
    .price {
      position: relative;
      padding: 29px 0% 0;
      margin: 0;
      font-size: 12px;
      .special-price {
        width: 25%;
        top: 10px;
        margin: 0 auto;
        -webkit-animation-name: mySlideOutDown;
        animation-name: mySlideOutDown;
      }
    }
    .bottom-part {
      font-size: 12px;
      .buy-price {
        margin-right: 13px;
      }
      .buy-btn {
        font-size: 14px;
        line-height: 18px;
        border-radius: 15px;
        background: linear-gradient(360deg, #d92e21 0%, #ff766c 100%);
      }
    }
  }

  .stock-empty {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .sellout {
    background-color: #7c7c7c !important;
  }
}
</style>
