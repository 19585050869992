<template>
  <van-action-sheet v-model="visible" @open="onOpen" :title="returnPurchaseSheetTitle">
    <div class="goods-purchase-form px2">
      <div class="info-zone">
        <div class="flex">
          <van-image
            class="flex-none"
            :src="image"
            width="92"
            height="92"
            radius="5"
            fit="cover"
            @click="onClickImage"
          />

          <div class="ml2 flex flex-column justify-between flex-auto">
            <div
              v-if="activityType === 'timeLimit' || activityType === 'returnScore'"
              class="fs16 bold"
              style="color: #e54635"
            >
              <span class="">{{ Number(sku.salePrice) }}元</span>
            </div>
            <div v-else class="fs16 bold" style="color: #e54635">
              <span style="margin-left: 2px"> {{ sku.costPoints }} </span>
              <span>{{ globalPointName }}</span>
              <span class="" v-if="showGoodPrice">+{{ Number(sku.salePrice) }}元</span>
            </div>
            <div v-if="activityType === 'drawPrize' && sku" class="degree-of-heat flex items-center">
              <div class="fs12" style="color: #ff252e">中奖券后价</div>
              <div class="fs18" style="color: #ff252e">{{ sku.salePrice }}元</div>
            </div>
            <div
              v-if="activityType !== 'timeLimit' && activityType !== 'returnScore' && showGoodPrice"
              class="degree-of-heat flex items-center"
            >
              <img style="width: 18px; height: 18px" class="" src="~/base/assets/img/money.png" />
              <div class="fs13" style="margin-left: 3px">
                可用
                <span class="color_primary bold"> {{ sku.costPoints }}{{ globalPointName }} </span>
                抵用
                <span class="color_primary bold">
                  {{ (Number(sku.originalPrice) - Number(sku.salePrice)).toFixed(2) }}元
                </span>
              </div>
            </div>
            <div class="flex flex-wrap mt1 justify-between" style="row-gap: 0.25rem">
              <span v-for="(tag, index) in tags" :key="index" class="service-title px1">
                {{ tag.title }}
              </span>
            </div>
            <div class="spec-str van-multi-ellipsis--l2 mt1 flex items-start">
              <img style="width: 18px; height: 18px" class="mr1" src="~/base/assets/img/icon-choose.png" />
              <span class="label fs13" style="color: #000; white-space: nowrap">已选：</span>
              <div class="fs13 flex" style="gap: 4px 8px; flex-wrap: wrap">
                <span style="white-space: nowrap" v-for="(item, index) in formatSpec(selectedSpec)" :key="index">{{
                  item.label + '：' + item.value
                }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="my2 spec-zone" v-if="stepIndex === 0">
          <div v-for="(specValues, specName, index) in goods.attributeMap" :key="index">
            <div class="mb1 mt2 spec-title">{{ specName }}</div>
            <div class="flex flex-wrap">
              <van-tag
                v-for="(value, j) in specValues"
                :key="j"
                round
                @click="onSelectSpec(specName, value)"
                class="mr2 my1"
                :class="{ invalid: !(validAttrs[specName] && validAttrs[specName][value]) }"
                size="large"
                :color="value === selectedSpec[specName] ? 'rgba(229, 70, 53, 0.15)' : '#EEEEEE'"
                :text-color="value === selectedSpec[specName] ? '#d92e21' : '#333333'"
              >
                {{ value }}
              </van-tag>
            </div>
          </div>
        </div>

        <div class="mt2 spec-zone" v-if="stepIndex === 1 && !alipayAddressAuth">
          <van-form @submit="onSubmit">
            <div class="flex justify-between header pt1 px2">
              <div class="mb1 flex items-center">
                <div class="dot bg_primary mr1"></div>
                <div class="title fs17 color_black_222">填写收货地址</div>
              </div>
              <div class="flex items-center" v-if="isInWechat" @click="importWxAddress">
                <van-image width="24" height="24" src="https://img.bj0yx.com/890935459708928" />
                <div class="mx1 title fs14 color_black_333">导入微信地址</div>
                <IconFont size="12px" icon="iconpageleft" color="#333333"></IconFont>
              </div>
            </div>
            <van-field
              class="field"
              v-model="form.receiverName"
              required
              label="收货人姓名"
              placeholder="收货人姓名"
              :rules="[{ required: true, message: '请输入收货人姓名' }]"
            />
            <van-field
              class="field"
              v-model="form.phone"
              required
              type="tel"
              label="手机号码"
              placeholder="收货人手机号码"
              :rules="[
                { required: true, message: '请输入收货人手机号码' },
                { pattern: /^1[3-9][0-9]\d{8}$/, message: '手机号码格式错误' }
              ]"
            />
            <van-field
              class="field"
              readonly
              clickable
              required
              v-model="form.address"
              label="省市区县"
              autosize
              type="textarea"
              rows="1"
              placeholder="点击选择省市区县"
              :rules="[{ required: true, message: '请选择省市区县' }]"
              @click="areaPopup = true"
            />
            <van-field
              class="field"
              v-model="form.addressDetail"
              required
              label="详细地址"
              autosize
              type="textarea"
              rows="1"
              placeholder="小区楼栋/乡村名称"
              :rules="[{ required: true, message: '请输入小区楼栋/乡村名称' }]"
            />
            <div class="flex justify-center items-center my2">
              <div class="color_primary">*以上信息将加密发送</div>
            </div>
          </van-form>
        </div>
        <div class="mt2 spec-zone" v-if="stepIndex === 1 && alipayAddressAuth">
          <div class="contact-card-container readonly px2 bg-white rounded-large" style="">
            <div v-if="form.receiverName" class="flex justify-between">
              <div v-if="form.receiverName" @click="toEdit" class="flex items-center py2">
                <img class="mr1" src="https://img.bj0yx.com/875400410955776" style="width: 25px; height: 25px" />
              </div>
              <div class="flex-auto py2 ml1">
                <div class="flex items-end color_black_222">
                  <div class="fs16 bold">{{ form.receiverName }}</div>
                  <div class="ml1 fs16 bold">{{ form.phone }}</div>
                </div>
                <div class="mt1 fs14 color_gray_666 ellipsis" style="text-align: left">
                  {{ `${form.address}` }}
                </div>
              </div>
              <div v-if="form.receiverName" @click="toEdit" class="flex items-center py2">
                <div class="flex justify-end items-center" style="width: 60px">
                  <div style="color: #999999; font-size: 14px">修改</div>
                  <IconFont size="14px" icon="iconpageleft" color="#999999"></IconFont>
                </div>
              </div>
            </div>
            <div v-else @click="toEdit" class="flex flex-auto items-center justify-between py3 px2">
              <div class="flex items-center">
                <IconFont size="40rpx" icon="iconzhifubao" color="#226bf3"></IconFont>
                <div class="ml3">导入淘宝地址</div>
              </div>
              <div class="flex items-center">
                <IconFont size="30rpx" icon="iconpageleft" color="#272636"></IconFont>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="stepIndex === 0 && needFillAddress"
        class="submit-btn bg_primary white flex flex-column items-center justify-center"
        @click="nextStep"
      >
        <div class="submit-text">下一步</div>
      </div>
      <div v-else class="submit-btn bg_primary white flex flex-column items-center justify-center" @click="onSubmit">
        <div class="extra-text">{{ extraText }}</div>
        <div class="submit-text">{{ submitText }}</div>
      </div>

      <van-popup v-model="areaPopup" position="bottom">
        <area-picker title="选择省市区县" :area-list="areaList" @confirm="areaConfirmFn" @cancel="areaPopup = false" />
      </van-popup>
    </div>
  </van-action-sheet>
</template>

<script>
import { mapState } from 'vuex'
import { Toast, ImagePreview, ActionSheet } from 'vant'
import { IconFont, AreaPicker } from 'components'
import { getAddressCode } from 'base/utils'
import utils from 'base/utils'
import wechatSdk from 'base/utils/wechatJssdk'

export default {
  name: 'PurchaseActionSheet',
  components: {
    [ActionSheet.name]: ActionSheet,
    IconFont,
    AreaPicker
  },

  props: {
    goods: {
      type: Object,
      default: () => {}
    },

    contactInfo: {
      type: Object,
      default: () => {}
    },

    activityType: {
      type: String,
      default: () => 'point'
    },

    areaList: {
      type: Object,
      default: () => {}
    },

    alipayAddressAuth: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      visible: false,
      salePrice: null,
      point: null,
      originalPrice: null,
      sku: {},
      selectedSpec: {},
      validAttrs: [],
      needFillAddress: true,
      stepIndex: 0, // 0: 选择规格, 1: 填写地址
      form: {},
      areaPopup: false,
      tags: [{ title: '差必赔' }, { title: '假一赔十' }, { title: '7天退换' }, { title: '全国包邮' }]
    }
  },

  computed: {
    ...mapState({
      points: (state) => state.user.points.activePoints,
      isInAliPay: (state) => state.appState.isInAliPay,
      isInWechat: (state) => state.appState.isInWechat,
      wechatSdkReject: (state) => state.appState.wechatSdkReject,
      showGoodPrice: (state) => state.appState.showGoodPrice
    }),

    isUsePoint() {
      return this.sku.costPoints > 0 && this.activityType === 'point'
    },

    image() {
      if (this.sku && this.sku.goodsImg !== '') {
        return this.sku.goodsImg
      } else if (this.goods.iconUrl && this.goods.iconUrl !== '') {
        return this.goods.iconUrl
      } else {
        return this.goods.bannerImgList && this.goods.bannerImgList[0]
      }
    },

    submitText() {
      if (this.activityType === 'timeLimit') {
        return '立即兑换'
      } else if (this.activityType === 'seckill') {
        return '领券兑换'
      } else if (this.activityType === 'returnScore') {
        return '立即兑换'
      } else if (this.activityType === 'drawPrize') {
        return '立即领取'
      }

      return `立即${this.globalExchangeWord}`
    },

    extraText() {
      if (this.sku && this.activityType === 'returnScore') {
        return `下单返还${this.sku.salePrice}${this.globalPointName}`
      }

      return null
    },

    returnNumberOfBuyers() {
      return _.random(500, 1000)
    },

    returnPurchaseSheetTitle() {
      if (this.showGoodsCardName)
        return (
          this.goods.goodsDetailEx.goodsCardName ||
          this.goods.goodsDetailEx.goodsDetailDesc ||
          this.goods.goodsDetailEx.goodsName ||
          '商品规格选择'
        )
      return this.goods.goodsDetailEx.goodsName || '商品规格选择'
    }
  },

  mounted() {},

  methods: {
    checkContactInfo(contact) {
      if (!contact) {
        Toast.fail('请先填写收货信息')
        return false
      }

      if (!contact.receiverName || !contact.receiverName.trim()) {
        // Toast.fail('收货人姓名填写错误，不能为空，也不可包含特殊字符')
        return false
      }

      if (contact.receiverName.length > 20) {
        // Toast.fail('收货人姓名不能超过20个字符')
        return false
      }

      if (!contact.phone || contact.phone == '') {
        // Toast.fail('手机号码不能为空')
        return false
      }

      const re = /^1[3-9][0-9]\d{8}$/
      if (contact.phone.length != 11 || !re.test(contact.phone)) {
        // Toast.fail('请填写有效的手机号码')
        return false
      }

      if (!contact.addressProvince || contact.addressProvince == '' || contact.addressProvince == '所属省份') {
        // Toast.fail('请选择所属省份')
        return false
      }

      if (!contact.addressCity || contact.addressCity == '' || contact.addressCity == '所属地级市') {
        // Toast.fail('请选择所属地级市')
        return false
      }

      if (!contact.addressArea || contact.addressArea == '' || contact.addressArea == '所属区县') {
        // Toast.fail('请选择所属区县')
        return false
      }

      if (!contact.addressDetail || !contact.addressDetail.trim()) {
        // Toast.fail('您填写详细地址无效，不可为空，也不可包含特殊字符')
        return false
      }

      if (contact.addressDetail.length > 200) {
        // Toast.fail('详细地址过长不能超过200个字符')
        return false
      }

      if (utils.noEmoji(contact.receiverName) == '') {
        // Toast.fail('收货人姓名不能为特殊字符,请重新输入')
        return false
      }

      if (utils.noEmoji(contact.addressDetail) == '') {
        // Toast.fail('详细地址不能为特殊字符,请重新输入')
        return false
      }

      if (utils.noEmoji(contact.receiverName).trim().length > 20) {
        // Toast.fail('收货人姓名过长,请重新输入')
        return false
      }

      if (utils.noEmoji(contact.addressDetail).trim().length > 200) {
        // Toast.fail('详细地址过长,请重新输入')
        return false
      }

      return true
    },

    show() {
      this.visible = true
    },

    close() {
      this.visible = false
    },

    setNeedFillAddress(params) {
      this.needFillAddress = params
    },

    setStepIndex(params) {
      this.stepIndex = params
    },

    onSelectSpec(specName, value) {
      if (!(this.validAttrs[specName] && this.validAttrs[specName][value])) {
        return
      }

      this.$set(this.selectedSpec, specName, value)

      this.onChangeSpec()
    },

    onChangeSpec() {
      const skuList = this.goods.goodsSpecList
      this.sku = _.find(skuList, (ele) => {
        const attrs = JSON.parse(ele.goodsAttribute)
        for (const key in attrs) {
          if (attrs[key] !== this.selectedSpec[key]) {
            return false
          }
        }
        return true
      })
      this.updateValidAttrs()
    },

    updateValidAttrs() {
      const attrList = []
      for (const key in this.goods.attributeMap) {
        for (const value of this.goods.attributeMap[key]) {
          attrList.push({ key, value })
        }
      }

      const validAttrs = []
      for (const attr of attrList) {
        const target = Object.assign({}, this.selectedSpec, { [attr.key]: attr.value })
        const valid = _.find(this.goods.goodsSpecList, (ele) => {
          const skuAttr = JSON.parse(ele.goodsAttribute)
          for (const key in target) {
            if (target[key] && skuAttr[key] !== target[key]) {
              return false
            }
          }
          return true
        })
        attr.valid = valid ? true : false

        if (!validAttrs[attr.key]) {
          validAttrs[attr.key] = []
        }
        validAttrs[attr.key][attr.value] = valid ? true : false
      }

      this.validAttrs = validAttrs
    },

    onOpen() {
      if (
        !this.sku ||
        this.sku.goodsId !== this.goods.goodsDetailEx.goodsId // 防止商品详情页跳商品详情页时，此组件不重新加载出现数据不一致
      ) {
        this.sku = this.goods.goodsSpecList[0]
      }
      // this.form = this.contactInfo
      // this.needFillAddress =
      //   this.contactInfo && this.contactInfo.addressDetail && this.contactInfo.addressDetail.trim() !== ''
      //     ? false
      //     : true
      if (this.checkContactInfo(this.contactInfo)) {
        this.needFillAddress = false
        this.form = this.contactInfo
      }
      if (this.goods.goodsSpecList.length > 1) {
        this.stepIndex = 0
      } else {
        this.stepIndex = 1
      }

      this.selectedSpec = {}

      if (this.sku) {
        const skuAttr = JSON.parse(this.sku.goodsAttribute)
        for (const key in this.goods.attributeMap) {
          this.$set(this.selectedSpec, key, skuAttr[key])
        }
      }

      this.onChangeSpec()
    },

    onSubmit() {
      if (!this.sku) {
        Toast.fail('请先选择规格!')
        return
      }

      this.$emit('submit', {
        sku: this.sku,
        contact: this.form
      })
    },

    onClickImage() {
      ImagePreview([this.image])
    },

    nextStep() {
      if (this.stepIndex === 0) {
        if (!this.sku) {
          Toast.fail('请先选择规格!')
          return
        }

        this.stepIndex = 1
      }
    },

    areaConfirmFn(values) {
      this.areaPopup = false
      this.areaVlues = values
      this.form.address = values.map((item) => item.name).join(' ')
      this.form.addressProvince = values[0].name
      this.form.addressCity = values[1].name
      this.form.addressArea = values[2].name
      this.form.addressCode = values[2].code
    },

    noEmoji(str, s) {
      if (!str) {
        return ''
      }

      const reg =
        /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g
      if (str.match(reg)) {
        return str.replace(reg, s || '')
      }
      return str
    },

    formatAlipayAddress(data) {
      this.form.receiverName = data?.fullname
      // this.$set(this.form, 'receiverName', data?.fullname)
      this.form.phone = data?.mobilePhone
      // this.$set(this.form, 'phone', data?.mobilePhone)
      this.form.addressProvince = data?.prov
      // this.$set(this.form, 'addressProvince', data?.prov)
      this.form.addressCity = data?.city
      // this.$set(this.form, 'addressCity', data?.city)
      this.form.addressArea = data?.area
      // this.$set(this.form, 'addressArea', data?.area)
      this.form.addressCode = getAddressCode(data?.prov, data?.city, data?.area) || 0
      // this.$set(this.form, 'addressCode', getAddressCode(data?.prov, data?.city, data?.area))
      this.form.addressDetail = data?.address
      // this.$set(this.form, 'addressDetail', data?.address)
      this.form.address = `${data?.prov}${data?.city}${data?.area}${data?.address}`
      // this.$set(this.form, 'address', `${data?.prov}${data?.city}${data?.area}${data?.address}`)
    },

    toEdit() {
      // console.log('postMessage 小程序获取地址')
      // eslint-disable-next-line no-undef
      my.postMessage({ action: 'execMy', method: 'getAddress' })

      // eslint-disable-next-line no-undef
      my.onMessage = (e) => {
        // console.log('接收小程序发过来的信息-2', e)
        if (!e?.result && !e?.result?.result) return
        this.formatAlipayAddress(e?.result?.result ?? e?.result)
        // console.log('addressInfo', this.form)
      }
    },

    formatSpec(obj) {
      const res = []
      for (const key in obj) {
        res.push({
          label: key,
          value: obj[key]
        })
      }
      return res
    },

    importWxAddress() {
      if (!this.isInWechat) return
      if (!this.wechatSdkReject) {
        Toast('暂未开放，请手动输入收货地址')
      } else {
        wechatSdk.openAddress().then(async (res) => {
          this.form = {
            receiverName: res?.userName,
            addressProvince: res?.provinceName,
            addressCity: res?.cityName,
            addressArea: res?.countryName,
            addressDetail: res?.addressDetailInfoNew,
            phone: res?.telNumber,
            address: `${res?.provinceName} ${res?.cityName} ${res?.countryName}`,
            addressCode: getAddressCode(res?.provinceName, res?.cityName, res?.countryName) || 0
          }
          await utils.waitFor(500)
          if (!this.form.addressCode) {
            Toast.fail('请重新选择省市区')
            this.form.addressProvince = ''
            this.form.addressCity = ''
            this.form.addressArea = ''
            this.form.address = ''
            this.form.addressCode = 0
          }
        })
      }
    }
  }
}
</script>
<style lang="less">
.purchase-action-sheet {
  &.van-popup {
    overflow-y: hidden;
  }
}
.goods-purchase-form {
  .van-tag--large {
    padding: 6px 12px;
  }
}
</style>
<style lang="less" scoped>
.goods-purchase-form {
  .info-zone {
    .spec-zone {
      // 此处不能写死高度，写死会导致vivo浏览器底部遮挡
      min-height: 200px;
      // overflow: scroll;
    }
  }

  .dot {
    width: 6px;
    height: 18px;
    border-radius: 3px;
    background-color: #d92e21;
  }

  .spec-str {
    .label {
      color: #9c9c9c;
    }
    font-size: 13px;
    color: #d92e21;
  }
  .degree-of-heat {
    color: #666666;
    img {
      width: 18px;
      height: 23px;
    }
  }
  .service-title {
    color: #b29183;
    border: 1px solid #bf987e;
    border-radius: 5px;
    font-size: 0.5rem;
    white-space: nowrap;
  }

  .invalid {
    background-color: #eeeeee !important;
    color: rgba(51, 51, 51, 0.3) !important;
    text-decoration: line-through;
  }

  .spec-title {
    font-size: 15px;
  }

  .submit-btn {
    margin-bottom: 20px;
    border-radius: 22px;
    height: 44px;
    text-align: center;
    color: #fff;

    .extra-text {
      font-size: 12px;
    }

    .submit-text {
      font-size: 16px;
    }
  }
}

.contact-card-container {
  background-color: #f2f2f2;
  position: relative;
  overflow: hidden;
  top: 50px;

  &.readonly::before {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 2px;
    background: -webkit-repeating-linear-gradient(
      135deg,
      #ff6c6c 0,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #1989fa 0,
      #1989fa 45%,
      transparent 0,
      transparent 50%
    );
    background: repeating-linear-gradient(
      -45deg,
      #ff6c6c 0,
      #ff6c6c 20%,
      transparent 0,
      transparent 25%,
      #1989fa 0,
      #1989fa 45%,
      transparent 0,
      transparent 50%
    );
    background-size: 80px;
    content: '';
  }
}
::v-deep .van-action-sheet__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding: 0 50px 0 20px;
}
</style>
