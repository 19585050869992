<template>
  <div class="tabbar">
    <div class="h-[50px]"></div>
    <van-tabbar v-model="tabActive" active-color="#E54635" :safe-area-inset-bottom="true" @change="onTabChange">
      <van-tabbar-item v-for="(item, index) in tabList" :key="index" :to="item.to" :name="item.to.path">
        <span>{{ item.label }}</span>
        <template #icon="props">
          <img :src="props.active ? item.icon.active : item.icon.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
import { trackEvent, trackUserEvent } from 'base/utils/rp'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'
export default {
  data() {
    return {
      tabActive: 0,
      tabList: [
        {
          label: '首页',
          icon: {
            active: require('base/assets/img/tabbar/home_active.png'),
            inactive: require('base/assets/img/tabbar/home.png')
          },

          to: { path: '/home' }
        },
        {
          label: '客服',
          icon: {
            active: require('base/assets/img/tabbar/customer_active.png'),
            inactive: require('base/assets/img/tabbar/customer.png')
          },

          to: { path: '/customer' }
        },
        {
          label: '我的',
          icon: {
            active: require('base/assets/img/tabbar/mine_active.png'),
            inactive: require('base/assets/img/tabbar/mine.png')
          },

          to: { path: '/mine' }
        }
      ]
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    $route: {
      handler: function (newVal) {
        console.log('watch $route', newVal.path)
        this.tabActive = newVal.path
      },

      immediate: true
    }
  },

  methods: {
    onTabChange(path) {
      this.tabChangePath = path
      const tab = this.tabList.find((tab) => tab.to.path === path)
      const tabName = tab && tab.label
      trackEvent({
        category: `点击-底部导航-${tabName}`
      })
      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        webUrl: window.location.href,
        clickPath: path
      }
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.FOOT_BAR_THEMED_CLICK.value,
        eventInfo
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_bottomtab_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          tab_name: tabName
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
