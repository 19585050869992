<template>
  <div class="flex flex-column purchase-card">
    <div class="sku">
      <div class="title bold ml2 mb1 fs16">填写订单</div>
      <div class="relative px2">
        <div class="satisfaction">
          <div class="flex items-center main-zone">
            <IconFont size="18px" icon="iconstar" color="#FEB51A" class="mr1"></IconFont>
            <div class="tips">恭喜! 您购买的商品好评率达到{{ goodCommentRate }}%</div>
          </div>
          <div class="tringle"></div>
        </div>
        <div class="goods-info flex justify-between pb2_5 mt2">
          <van-image
            width="90px"
            height="90px"
            class="flex-none goods-image"
            :radius="5"
            :src="image"
            fit="cover"
            @click="onClickImage"
          />
          <!-- <div v-if="orderDetail.activityType === ENUM_ACTIVITY_TYPE_BARGAIN" class="bargain-tag flex justify-center">砍</div> -->
          <div class="flex flex-column justify-between flex-auto ml2">
            <div>
              <p class="goods-name van-ellipsis fs16">{{ goods.goodsDetailEx.goodsName }}</p>
              <p class="spec-desc van-ellipsis fs13" style="color: #888888">{{ formatSpec(selectedSpec) }}</p>
            </div>
            <div class="flex items-center fs14">
              <!-- <div v-if="orderDetail.activityType === ENUM_ACTIVITY_TYPE_BARGAIN" class="flex color_red_E9627A bold">0元</div> -->
              <!-- <div v-if="orderDetail.activityType === ENUM_ACTIVITY_TYPE_NONE" class="flex items-end"> -->
              <!-- <div v-if="isUsePoint" class="bg_primary fs17 white rounded-small px1">{{ orderDetail.costPoints }}{{globalPointName}}</div> -->
              <!-- <div v-if="isUsePoint" class="color_primary ml1">+</div> -->
              <!-- <div v-if="isDrawPrizeOrder" class="color_primary mr1 fs12">中奖券后价</div> -->
              <span class="color_primary fs16 mr2">{{ Number(sku.salePrice).toFixed(2) }}元</span>
              <!-- </div> -->
              <!-- <div v-if="orderDetail.activityType === ENUM_ACTIVITY_TYPE_SECKILL" class="flex color_red_E9627A bold">{{ orderDetail.goodsTotalAmount }}元</div> -->
              <div class="color_primary fs16" style="text-decoration: line-through">
                {{ Number(sku.originalPrice).toFixed(2) }}元
              </div>
            </div>
          </div>
        </div>
        <div class="my2 spec-zone">
          <div v-for="(specValues, specName, index) in goods.attributeMap" :key="index">
            <div class="mb1 mt2 spec-title">{{ specName }}</div>
            <div class="flex flex-wrap">
              <van-tag
                v-for="(value, j) in specValues"
                :key="j"
                round
                @click="onSelectSpec(specName, value)"
                class="mr2 my1"
                :class="{ invalid: !(validAttrs[specName] && validAttrs[specName][value]) }"
                size="large"
                :color="value === selectedSpec[specName] ? 'rgba(229, 70, 53, 0.15)' : '#EEEEEE'"
                :text-color="value === selectedSpec[specName] ? '#d92e21' : '#333333'"
              >
                {{ value }}
              </van-tag>
            </div>
          </div>
        </div>
      </div>
      <van-divider :style="{ height: '8px', background: '#efefef', margin: '15px 0' }" />
      <div class="title flex ml2 mb1 fs16">
        <div class="bold">填写收货信息</div>
        <div class="color_primary">（以下信息将加密发送）</div>
      </div>
      <div class="spec-zone custom-spec-zone">
        <van-form>
          <van-field
            class="field"
            v-model="form.receiverName"
            label="收货姓名"
            placeholder="请输入收货人姓名"
            size="large"
            center
            :rules="[{ required: true, message: '请输入收货人姓名' }]"
          />
          <van-field
            class="field"
            v-model="form.phone"
            type="tel"
            label="手机号码"
            placeholder="请输入收货手机号"
            size="large"
            center
            :rules="[
              { required: true, message: '请输入手机号码' },
              { pattern: /^1[3-9][0-9]\d{8}$/, message: '手机号码格式错误' }
            ]"
          />
          <van-field
            class="field field-address relative"
            readonly
            clickable
            v-model="form.address"
            label="省市区县"
            autosize
            rows="1"
            placeholder="点击选择省市区县"
            size="large"
            center
            :rules="[{ required: true, message: '请选择省市区县' }]"
            @click="areaPopup = true"
          >
            <div slot="right-icon" class="absolute" style="right: 10px; top: 20px">
              <IconFont slot="right-icon" size="12px" icon="iconpageleft" color="#888888"></IconFont>
            </div>
          </van-field>
          <van-field
            class="field"
            v-model="form.addressDetail"
            label="详细地址"
            autosize
            type="textarea"
            rows="1"
            placeholder="如街区、小区门牌等详细信息"
            size="large"
            center
            :rules="[{ required: true, message: '请输入详细地址' }]"
          />
        </van-form>
      </div>
      <van-divider :style="{ height: '8px', background: '#efefef', margin: '15px 0' }" />
      <div class="title bold ml2 mb1 fs16">支付方式</div>
      <div class="spec-zone px2 flex justify-between">
        <div class="flex items-center">
          <IconFont size="23px" icon="iconqianbao1" color="#656565" class="mr1"></IconFont>
          <div class="flex items-center">货到付款 (验货满意后付款)</div>
        </div>
        <div>
          <van-radio name="1" v-model="radio" checked-color="#d92e21" />
        </div>
      </div>
      <van-divider :style="{ height: '8px', background: '#efefef' }" />
      <div class="flex flex-column px2">
        <div class="flex justify-between pb3 bold" style="border-bottom: 1px solid #dddddd">
          <div class="bold fs16">配送方式</div>
          <div class="bold fs16">免费包邮</div>
        </div>
        <div class="flex justify-between py3 bold" style="border-bottom: 1px solid #dddddd">
          <div class="bold fs16">商品金额</div>
          <div class="bold color_primary fs16">￥{{ Number(sku.originalPrice).toFixed(2) }}</div>
        </div>
        <div class="flex justify-between py3 bold" style="border-bottom: 1px solid #dddddd">
          <div class="bold fs16">优惠金额</div>
          <div class="bold color_primary fs16">-￥{{ Number(sku.originalPrice - sku.salePrice).toFixed(2) }}</div>
        </div>
        <div class="flex justify-between py3 bold" style="border-bottom: 1px solid #dddddd">
          <div class="bold fs16">实付款</div>
          <div class="bold color_primary fs16">￥{{ Number(sku.salePrice).toFixed(2) }}</div>
        </div>
        <div class="mt2 mb1">
          <van-checkbox v-model="anonymity" checked-color="#d92e21">匿名购买</van-checkbox>
        </div>
        <div class="my2 flex items-center justify-center">
          <div class="order-protect">
            <img class="order-protect-img" src="~base/assets/img/orderProtect.png" alt="" />
          </div>
        </div>
      </div>
      <div class="px2 mt3">
        <van-button
          class="bg_primary"
          block
          type="danger"
          size="large"
          native-type="submit"
          loading-text="提交中..."
          @click="onSubmit"
          style="border-radius: 4px"
          >提交订单
        </van-button>
      </div>
    </div>
    <van-popup v-model="areaPopup" position="bottom">
      <area-picker title="选择省市区县" :area-list="areaList" @confirm="areaConfirmFn" @cancel="areaPopup = false" />
    </van-popup>
  </div>
</template>

<script>
import { Toast, ImagePreview } from 'vant'
import { IconFont, AreaPicker } from 'components'
import areaList from 'base/utils/area'

export default {
  name: 'PurchaseCard',
  components: { IconFont, AreaPicker },
  props: {
    goods: {
      type: Object,
      default: () => {}
    },

    contactInfo: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      // TODO
      goodCommentRate: 98,
      validAttrs: [],
      selectedSpec: {},
      sku: null,
      form: {},
      areaPopup: false,
      areaList: areaList,
      radio: '1',
      anonymity: true
    }
  },

  computed: {
    image() {
      // console.log(this.sku, this.goods)
      if (this.sku && this.sku.goodsImg !== '') {
        return this.sku.goodsImg
      } else if (this.goods.iconUrl && this.goods.iconUrl !== '') {
        return this.goods.iconUrl
      } else {
        return this.goods.bannerImgList && this.goods.bannerImgList[0]
      }
    }
  },

  created() {
    if (
      !this.sku ||
      this.sku.goodsId !== this.goods.goodsDetailEx.goodsId // 防止商品详情页跳商品详情页时，此组件不重新加载出现数据不一致
    ) {
      this.sku = this.goods.goodsSpecList[0]
    }

    this.form = this.contactInfo
    this.needFillAddress =
      this.contactInfo && this.contactInfo.addressDetail && this.contactInfo.addressDetail.trim() !== '' ? false : true

    this.selectedSpec = {}

    if (this.sku) {
      const skuAttr = JSON.parse(this.sku.goodsAttribute)
      for (const key in this.goods.attributeMap) {
        this.$set(this.selectedSpec, key, skuAttr[key])
      }
    }
    this.onChangeSpec()
  },

  methods: {
    onClickImage() {
      ImagePreview([this.image])
    },

    onSelectSpec(specName, value) {
      if (!(this.validAttrs[specName] && this.validAttrs[specName][value])) {
        return
      }

      this.$set(this.selectedSpec, specName, value)

      this.onChangeSpec()
      // console.log(specName, value, this.selectedSpec[specName])
    },

    onChangeSpec() {
      const skuList = this.goods.goodsSpecList

      this.sku = _.find(skuList, (ele) => {
        const attrs = JSON.parse(ele.goodsAttribute)
        for (const key in attrs) {
          if (attrs[key] !== this.selectedSpec[key]) {
            return false
          }
        }
        return true
      })

      this.updateValidAttrs()

      // console.log(this.sku)
    },

    updateValidAttrs() {
      const attrList = []
      for (const key in this.goods.attributeMap) {
        for (const value of this.goods.attributeMap[key]) {
          attrList.push({ key, value })
        }
      }

      const validAttrs = []
      for (const attr of attrList) {
        const target = Object.assign({}, this.selectedSpec, { [attr.key]: attr.value })
        const valid = _.find(this.goods.goodsSpecList, (ele) => {
          const skuAttr = JSON.parse(ele.goodsAttribute)
          for (const key in target) {
            if (target[key] && skuAttr[key] !== target[key]) {
              return false
            }
          }
          return true
        })
        attr.valid = valid ? true : false

        if (!validAttrs[attr.key]) {
          validAttrs[attr.key] = []
        }
        validAttrs[attr.key][attr.value] = valid ? true : false
      }
      // console.log(this.selectedSpec, attrList, validAttrs)

      this.validAttrs = validAttrs

      // console.log(attrList)
    },

    formatSpec() {
      return _.reduce(
        this.selectedSpec,
        (r, v, k) => {
          if (v) {
            r += `${k}: ${v} `
          }
          return r
        },
        ''
      )
    },

    onSubmit() {
      if (!this.sku) {
        Toast.fail('请先选择规格!')
        return
      }

      this.$emit('submit', {
        sku: this.sku,
        contact: this.form
      })
    },

    areaConfirmFn(values) {
      this.areaPopup = false
      this.areaVlues = values
      this.form.address = values.map((item) => item.name).join(' ')
      this.form.addressProvince = values[0].name
      this.form.addressCity = values[1].name
      this.form.addressArea = values[2].name
      this.form.addressCode = values[2].code
    },

    pay() {}
  }
}
</script>

<style lang="less">
.purchase-card {
  .van-cell::after {
    border: none;
  }
  .van-cell {
    padding-left: 0px;
  }
  .van-field__label {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0;
  }
  .van-field__control {
    // width: 544px;
    height: 50px;
    background: #eeeeee;
    border-radius: 4px;
    padding-left: 24px;
    box-sizing: border-box;
  }
  .field-address {
    input {
      width: 244px;
      font-size: 16px;
    }
  }
  .van-field__body {
    input::-webkit-input-placeholder {
      color: #999999;
      font-size: 16px;
    }
    input::-moz-placeholder {
      /* Mozilla Firefox 19+ */
      color: #999999;
      font-size: 16px;
    }
    input:-moz-placeholder {
      /* Mozilla Firefox 4 to 18 */
      color: #999999;
      font-size: 16px;
    }
    input:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #999999;
      font-size: 16px;
    }
  }
}
</style>
<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';

.satisfaction {
  background-image: linear-gradient(90deg, #fff8dc, #fffaf9);

  height: 35px;
  width: 100%;

  .main-zone {
    height: 35px;
    padding-left: 20px;
    .tips {
      color: #ce6906;
      font-size: 13px;
    }
  }

  .tringle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    // border-right: 5px solid transparent;
    border-top: 5px solid #ea7809;
  }
}
.invalid {
  background-color: #eeeeee !important;
  color: rgba(51, 51, 51, 0.3) !important;
  text-decoration: line-through;
}
.order-protect {
  width: 60%;
  .rem(height, 45px);
  img {
    width: 100%;
    height: 100%;
  }
}
// .spec-desc {
//   word-break: break-all;
//   text-overflow: ellipsis;
//   display: -webkit-box;
//   -webkit-box-orient: vertical;
//   -webkit-line-clamp: 3; /* 这里是超出几行省略 */
//   overflow: hidden;
// }
</style>
