import brand from 'base/brands'

const componentName = brand.renderBrandElement({ id: 'global.componentName' })?.toLowerCase()

const defaults = ['coupon', 'golds']
const getComponents = (key) => {
  if (defaults.includes(componentName)) return require(`./${key}`).default
  const component = require(`./${componentName}/${key}`).default
  return component || require(`./${key}`).default
}

const CommentGallery = getComponents('CommentGallery')
const PurchaseForm = getComponents('PurchaseForm')
const BottomBar = getComponents('BottomBar')
const ImageGallery = getComponents('ImageGallery')
const HeaderInfo = getComponents('HeaderInfo')
const PurchaseActionSheet = getComponents('PurchaseActionSheet')
const ServiceInfo = getComponents('ServiceInfo')
const PurchaseCard = getComponents('PurchaseCard')
const PurchaseCardFreeReceive = getComponents('PurchaseCardFreeReceive')
const SingleProductPurchaseCard = getComponents('SingleProductPurchaseCard')
const VerticalCommentGallery = getComponents('VerticalCommentGallery')
const GoodsDescOfVirtualCoupon = getComponents('goodsDescOfVirtualCoupon')
const DrawPrizeBottomBar = getComponents('DrawPrizeBottomBar')
const PurchaseCardCosmetic = getComponents('PurchaseCardCosmetic')
const PriceBanner = getComponents('PriceBanner')

export {
  CommentGallery,
  PurchaseForm,
  BottomBar,
  ImageGallery,
  HeaderInfo,
  PurchaseActionSheet,
  ServiceInfo,
  PurchaseCard,
  PurchaseCardFreeReceive,
  SingleProductPurchaseCard,
  VerticalCommentGallery,
  GoodsDescOfVirtualCoupon,
  DrawPrizeBottomBar,
  PurchaseCardCosmetic,
  PriceBanner
}
