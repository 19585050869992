<template>
  <div class="activity-area">
    <div class="base-info flex items-center">
      <div class="left-part flex flex-column p-[10px] justify-center items-start bg-DF2E24">
        <div v-if="renderBrandElement({ id: 'goods.hiddenPrice' }) !== 'true'" class="price flex items-baseline">
          <div class="flex items-center text-16">补贴价</div>
          <div class="flex items-baseline mr-[14px]">
            <span class="text-14">￥</span><span class="text-[22px] font-bold">{{ good.goodsDetailEx.price }}</span>
          </div>
          <div class="flex gap-[4px] text-14">
            <span>秒杀价</span><span>￥ {{ good.goodsDetailEx.originalPrice }}</span>
          </div>
        </div>
        <div class="text-12">{{ good.goodsDetailEx.saleDesc | sails }}</div>
      </div>
      <div class="right-part relative flex flex-column gap-[4px] justify-center items-center bg-FFC42A">
        <div class="triangle"></div>
        <div class="flex items-center">
          <IconFont size="18px" icon="iconnaozhong1" color="#760300"></IconFont>
          <span class="ml1">限时秒杀</span>
        </div>
        <count-down :end-time="countDownEnd"></count-down>
      </div>
    </div>
  </div>
</template>

<script>
import { trackEvent } from 'base/utils/rp'
import { IconFont, CountDown } from 'components'

const PERIODS = [0, 8, 9, 10, 12, 18, 20, 24]

export default {
  name: 'TimeLimit',
  components: { IconFont, CountDown },
  filters: {
    sails: function (value) {
      return _.replace(value, '已兑换', '已售')
    }
  },

  props: {
    good: { type: Object, default: new Object() }
  },

  data() {
    return {
      countDownTime: 0,
      countDownEnd: dayjs().endOf('d')
    }
  },

  created() {
    const currentHour = dayjs().get('hour')
    let i = 0
    for (i = 0; i < PERIODS.length - 1; i++) {
      if (currentHour < PERIODS[i]) break
    }
    // this.countDownEnd = dayjs().set('hour', PERIODS[i]).set('minute', 0).set('second', 0)
  },

  mounted() {},
  beforeDestroy() {},

  methods: {
    onClickArea(name, action) {
      trackEvent({
        category: `点击-详情页-${name}`,
        action: action
      })
    }
  }
}
</script>
<style></style>
<style lang="less" scoped>
.base-info {
  height: 68px;
  color: white;
  // font-size: 13px;
  .left-part {
    height: 100%;
    flex-basis: 70%;
    .price {
      height: 30px;
      .original-price {
        height: 30px;
        font-size: 26px;
      }
      .final-price {
        padding: 0 6px;
        text-decoration: line-through;
      }
    }
  }
  .right-part {
    height: 100%;
    flex-basis: 30%;
    .triangle {
      position: absolute;
      z-index: 10;
      left: -16px;
      width: 0;
      height: 0;
      border: 8px solid transparent;
      border-right: 8px solid #ffc42a;
    }
  }
}
.desc {
  font-size: 16px;
  padding: 9px;
  .hint {
    font-size: 14px;
    border-radius: 3px;
    padding: 1px 3px;
  }
}
.stock {
  padding: 9px;
  font-size: 13px;
  .title {
    margin-right: 15px;
    color: #929292;
  }
  .buy-limit {
    padding: 1px 3px;
  }
}
</style>
