<template>
  <div
    @click="onClick"
    class="container"
    :style="{ ...itemStyle }"
    v-track:exposure
    :track-params="JSON.stringify(eventInfo)"
  >
    <van-image class="waterfall-img In_c_Img" :src="source.imgUrl || null">
      <template v-slot:loading>
        <ImageThemed :width="100" image-id="goodsNormalItem.loading"></ImageThemed>
      </template>
    </van-image>
    <img :src="source.cornerImg" alt="" class="cornerImg" />
    <img v-if="source.stockNum <= 0" class="dw_img" src="~base/assets/img/base/dw.png" />
    <div class="goods-info flex flex-column">
      <div class="name bold ellipse-2">{{ source.goodsCardName || source.goodsDesc }}</div>

      <div class="goods-bottom">
        <div class="color_primary bold flex justify-between items-center">
          <span class="point fs16">补贴价￥{{ source.price }}</span>
          <span class="original-price color_tips">{{ source.originalPrice }}元</span>
        </div>
      </div>
      <div class="flex fs12 goods-desc gap-[5px]">
        <span>{{ discount }}折</span>
        <span>补贴金￥{{ source.costPoints }}</span>
      </div>
    </div>
    <div class="top" v-if="source.top <= 10">Top{{ source.top }}</div>
    <div class="tag">极速退款 | 包邮</div>
  </div>
</template>

<script>
import { trackUserEvent, webfunnyTrack } from 'base/utils/rp'
import ImageThemed from 'components/ImageThemed.vue'
import xEnum from 'base/utils/enum'
import { Dialog } from 'vant'
import { mapState } from 'vuex'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'GoodsNormalItem',
  components: { ImageThemed },
  props: {
    itemStyle: {
      type: Object,
      default: () => {}
    },

    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: () => 0
    },

    type: {
      type: Number,
      default: () => 1
    },

    pageSource: {
      type: Number,
      default: () => xEnum.ENUM_PAGE_SOURCE.UNKNOWN.getValue()
    },

    showRankTag: {
      type: Boolean,
      default: () => false
    },

    onClickItem: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {}
  },

  computed: {
    ...mapState({
      pId: (state) => state.appState.pId,
      userId: (state) => state.user.userId,
      phone: (state) => state.user.phone,
      openid: (state) => state.user.openid,
      unionId: (state) => state.user.unionId,
      webFingerPrint: (state) => state.user.webFingerPrint,
      showGoodPrice: (state) => state.appState.showGoodPrice
    }),

    discount() {
      return ((this.source.price / this.source.originalPrice) * 10).toFixed(1)
    },

    eventInfo() {
      return {
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_EP.value,
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_EXPOSE.value,
        eventInfo: {
          pId: this.pId,
          userId: this.userId,
          goodsId: this.source.id,
          goodsPrice: this.source.price,
          score: this.source.score,
          goodsGroupId: this.type,
          webUrl: window.location.href,
          index: (this.source.i + 1) * (this.source.j + 1) - 1,
          goodsName: this.source.goodsName,
          goodsIndex: `${this.source.j}`,
          goodsIndexVertical: `${this.source.i}`,
          goodsCategoryId: this.source.categoryId
        }
      }
    }
  },

  methods: {
    onClick() {
      const { source, index } = this
      if (source.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }
      let count = Number(sessionStorage.getItem('enterProfileCount'))
      if (count && count < 6) {
        count++
        sessionStorage.setItem('enterProfileCount', count)
      } else {
        sessionStorage.setItem('enterProfileCount', 1)
      }
      this.$router.push({
        path: `/goodsProfile/${source.id}`,
        query: {
          type: this.type,
          pageSource: this.pageSource
        }
      })

      this.onClickItem(source, index)

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        goodsName: source.goodsName,
        goodsPrice: this.source.price,
        score: this.source.score,
        goodsIndex: index,
        goodsGroupId: this.type,
        webUrl: window.location.href
      }
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_WATERFALL_CARD_CLICK.value,
        eventInfo
      })

      webfunnyTrack({
        pointId: xEnum.ENUM_WEBFUNNY_POINT_ID.GOODS_CLICK.value,
        eventType: 'goodsClick',
        eventInfo
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_hc_waterfalls_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_all_page',
        params: {
          goods_score: source?.score,
          goods_group_type: this.type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: `${this.source.j}`,
          goods_index_vertical: `${this.source.i}`,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_source_waterfall: '商品瀑布流',
          goods_category_id: source.categoryId
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'base/assets/styles/css/functions.less';
.btn {
  width: 100%;
  height: 30px;
  border-radius: 6px;
  color: white;
  background: linear-gradient(to right, rgb(255, 36, 0), rgb(254, 160, 5));
  font-family: 'PingFangSC-Medium';
}
.container {
  width: 100%;
  display: inline-block;
  border-radius: 8px;
  margin-bottom: 8px;
  position: relative;
  box-shadow: 0px 0px 6px rgba(128, 128, 128, 0.2);
  background: #fff;
  overflow: hidden;

  .cornerImg {
    position: absolute;
    right: 0px;
    top: 0px;
    height: 0.72rem;
    width: auto;
  }

  .In_c_Img,
  .dw_img {
    display: block;
    width: 100%;
    .rem(height, 175px);
  }

  .name {
    padding-top: 0.32rem;
    text-align: left;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* 显示的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(50vw - 32px);
  }
  .recommend-intro {
    max-width: 160px;
    color: #999999;
    .item {
      border-right: 1px solid;
    }
    .item:last-child {
      border-right: 0;
      padding-right: 0;
    }
    .item:first-child {
      padding-left: 0;
    }
  }

  .in_c_I_p {
    display: inline-block;
    vertical-align: middle;
  }

  .in_c_I_p1 {
    width: 100%;
    text-align: center;
    font-size: 0.3rem;
    color: #fff;
  }
  .goods-info {
    padding-bottom: 10px;
    box-sizing: border-box;
    padding-left: 8px;
    padding-right: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    .goods-desc {
      margin-top: 0.16rem;
      font-size: 12px;
      color: #ffffff;
      span {
        display: block;
        line-height: 19px;
        padding: 0 5px;
        border-radius: 2px;
        background: #f95c05;
        & + span {
          background: #ff0000;
        }
      }
    }
    .point {
      font-size: 16px;
    }
    .goods-bottom {
      margin-top: 4px;
      .original-price {
        margin-left: 0.16rem;
        text-decoration: line-through;
        font-weight: normal;
      }
    }
  }
  .top {
    width: 54px;
    height: 24px;
    background: linear-gradient(90deg, #fdc643 0%, #ff9e00 100%);
    border-radius: 0px 12px 12px 0px;
    position: absolute;
    top: 0;
    left: 0;
    font-size: 14px;
    color: #ffffff;
    text-align: center;
    line-height: 24px;
  }
  .tag {
    width: 98px;
    height: 21px;
    background: linear-gradient(90deg, #ed742d 0%, #e6ba3f 100%), linear-gradient(90deg, #ff8036 0%, #f8c842 100%);
    border-radius: 10px 0px 0px 0px;
    position: absolute;
    right: 0;
    text-align: center;
    line-height: 21px;
    top: 172px;
    font-size: 12px;
    color: #fff;
  }
}
</style>
