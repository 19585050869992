import { render, staticRenderFns } from "./GoodsWaterfallList.vue?vue&type=template&id=2929844c&scoped=true&"
import script from "./GoodsWaterfallList.vue?vue&type=script&lang=js&"
export * from "./GoodsWaterfallList.vue?vue&type=script&lang=js&"
import style0 from "./GoodsWaterfallList.vue?vue&type=style&index=0&id=2929844c&prod&lang=less&"
import style1 from "./GoodsWaterfallList.vue?vue&type=style&index=1&id=2929844c&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2929844c",
  null
  
)

export default component.exports