<template>
  <div @click="onClick" class="com-list-item-goods-more-item m2" v-track:exposure :track-params="getEventInfo()">
    <div class="goods-info flex justify-between p2">
      <van-image class="image flex-shrink-0" width="100" height="100" :src="source.imgUrl" fit="cover" />
      <div class="flex flex-column justify-start flex-auto ml2">
        <p class="fs16" :class="showGoodsCardName ? 'text-ellipsis' : ''" v-html="brightKeywords"></p>
        <div class="flex justify-between mt1">
          <div v-if="itemType === 'point'" class="flex items-center">
            <div class="color_primary" style="font-size: 16px">{{ source.costPoints }}{{ globalPointName }}</div>
            <div class="color_primary">+</div>
            <span class="color_primary">{{ source.price }}元</span>
          </div>
          <div v-if="itemType === 'seckill'" class="flex items-center">
            <div class="color_primary" style="font-size: 13px">补贴价</div>
            <span class="color_primary ml1" style="font-size: 16px">{{ source.price }}元</span>
          </div>
          <div>
            仅剩
            <span class="color_primary">{{ source.stockNum }}</span>
            件
          </div>
        </div>
        <div class="mt2" style="text-decoration: line-through; color: #888888">￥{{ source.originalPrice }}元</div>
      </div>
    </div>
    <div class="flex comment-container">
      <div class="flex comment items-center">
        <img class="avatar flex-none" :src="source.goodsComment && source.goodsComment.userLogo" />
        <div class="desc van-multi-ellipsis--l2 ml2">评价:{{ source.goodsComment && source.goodsComment.content }}</div>
      </div>
      <div class="btn bg_primary">立即{{ globalExchangeWord }}</div>
    </div>
  </div>
</template>

<script>
import { trackUserEvent } from 'base/utils/rp'
import { Dialog } from 'vant'
import xEnum from 'base/utils/enum'
import { sendQuickTrackingEvent, EventType } from 'base/utils/quickTracking'

export default {
  name: 'GoodsMoreItemThemed',
  props: {
    source: {
      type: Object,
      default() {
        return {}
      }
    },

    index: {
      type: Number,
      default: () => 0
    },

    type: {
      type: Number,
      default: () => 1
    },

    onClickItem: {
      type: Function,
      default: () => {}
    },

    keyWords: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      itemType: this.renderBrandElement({ id: 'goodsMoreItem.itemType' })
    }
  },

  computed: {
    //搜索高亮关键字
    brightKeywords() {
      if (!this.keyWords) return this.source.goodsName
      let brightGoodsName = this.showGoodsCardName
        ? this.source.goodsCardName || this.source.goodsDesc || this.source.goodsName
        : this.source.goodsName
      if (this.keyWords && this.keyWords.length > 0) {
        const goodsNameArr = this.splitgoodsName(this.source.goodsName.trim())
        goodsNameArr.forEach((item) => {
          if (this.keyWords.indexOf(item) !== -1 && item !== '') {
            const reg = new RegExp(item, 'g')
            const replaceStr = `<span class="color_primary">${item}</span>`
            brightGoodsName = brightGoodsName.replace(reg, replaceStr)
          }
        })
      }
      return brightGoodsName
    }
  },

  created() {
    // console.log(this.keyWords);
  },

  methods: {
    onClick() {
      const { source, index } = this

      const eventInfo = {
        pId: this.pId,
        userId: this.userId,
        goodsId: source.id,
        goodsName: source.goodsName,
        goodsIndex: index,
        goodsGroupId: this.type
      }
      trackUserEvent({
        eventType: 'goodsClick',
        eventInfo
      })

      sendQuickTrackingEvent({
        eventName: 'ducuecmc_sp_search_result_clk',
        eventType: EventType.Click,
        pageName: 'dacuecmc_search_page',
        params: {
          goods_score: source?.score,
          goods_group_type: this.type,
          goods_name: source.goodsName,
          goods_id: source.id,
          goods_index: index,
          display_price: source.price,
          display_original_price: source.originalPrice,
          goods_category_id: source.categoryId,
          search_keyword: this.keyWords
        }
      })

      if (source.stockNum <= 0) {
        Dialog.alert({
          message: '非常遗憾，本商品已兑完，您可以挑选其它商品',
          confirmButtonText: '好的'
        })
        return
      }

      this.$router.push({
        path: `/goodsProfile/${source.id}`,
        query: {
          type: this.type,
          activityType: this.itemType
        }
      })

      this.onClickItem(source, index)
    },

    splitgoodsName(goodsName) {
      const arr = []
      let goodsNameArr = []
      let startIndex = 0
      for (let i = 0; i < goodsName.length; i++) {
        // 找出分界线
        if (
          (goodsName.charCodeAt(i) > 255 && goodsName.charCodeAt(i - 1) < 255) ||
          (goodsName.charCodeAt(i) < 255 && goodsName.charCodeAt(i - 1) > 255)
        ) {
          const str = goodsName.substring(startIndex, i)
          arr.push(str)
          startIndex = i
        }
      }
      arr.push(goodsName.substring(startIndex))
      arr.forEach((item) => {
        if (item.charCodeAt(0) > 255) {
          goodsNameArr = [...goodsNameArr, ...item.split('')]
        } else {
          goodsNameArr.push(item)
        }
      })
      return goodsNameArr
    },

    getEventInfo() {
      return JSON.stringify({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.SEARCH_RESULT_GOODS_EP.value,
        eventInfo: {
          goodsId: this.source.id,
          goodsPrice: this.source.price,
          score: this.source.score,
          goodsGroupId: this.type,
          goodsName: this.source.goodsName,
          goodsIndex: this.index,
          eventSource: '搜索结果',
          goodsCategoryId: this.source.categoryId
        }
      })
    }
  }
}
</script>
<style lang="less">
.com-list-item-goods-more-item {
  height: 175px;
  .image > img {
    // width: 100px;
    border-radius: 5px;
  }

  .avatar,
  .avatar > img {
    width: 36px;
    border-radius: 50%;
  }
}
</style>

<style lang="less" scoped>
.com-list-item-goods-more-item {
  background-color: white;
  border-radius: 5px;
  box-shadow: 2px 2px 5px #ccc;

  .comment {
    width: 78%;
    height: 50px;

    .desc {
      color: #979797;
      font-size: 12px;
    }
  }

  .btn {
    height: 35px;
    line-height: 35px;
    align-self: center;
    width: 22%;
    color: white;
    text-align: center;
    border-radius: 25px;
    box-shadow: 0px 3px 12px 0px rgba(203, 33, 50, 0.35);
    font-size: 12px;
  }
  .comment-container {
    width: 98%;
    margin-left: 1%;
    padding: 0px 6px;
    box-sizing: border-box;
    justify-content: space-between;
    background-color: #eeeeee;
    border-radius: 25px;
    overflow: hidden;
  }
}
</style>
