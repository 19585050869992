/* eslint-disable */

import _ from 'lodash'
import xEnum from 'base/utils/enum'
import utils from 'base/utils'
import { trackEvent, trackUserEvent } from 'base/utils/rp'
import store from '@/store'

let initialState = {
  module: '未知模块',
  type: 0,
  payType: '未知支付类型'
}

let LOCAL_STORAGE_KEY = 'STATISTICS_KEY'
function loadStatisticsState() {
  let value = localStorage.getItem(LOCAL_STORAGE_KEY)
  try {
    return JSON.parse(value)
  } catch (err) {
    return _.cloneDeep(initialState)
  }
}

const appState = {
  state: _.cloneDeep(initialState),

  mutations: {
    UPDATE_STATISTICS_STATE: (state, { key, value }) => {
      state[key] = value
    },
    SAVE_STATISTICS_STATE: (state) => {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(state))
    }
  },

  actions: {
    async enterGoodsProfile({ commit, state, rootState }, statsInfo) {
      const { module, index, type, goods } = statsInfo

      commit('UPDATE_STATISTICS_STATE', {
        key: 'module',
        value: module
      })
      commit('UPDATE_STATISTICS_STATE', {
        key: 'type',
        value: type
      })
      commit('SAVE_STATISTICS_STATE')

      // console.info({
      //   category:`详情<-${module}`,
      //   action: `下标${index}`,
      //   opt_label: goods.goodsName,
      //   opt_value: `商品组-${type}`
      // })

      trackEvent({
        category: `进详情<-${module}`,
        action: `下标-${index}`,
        opt_label: goods.goodsName,
        opt_value: `商品组-${type}`
      })
      trackUserEvent({
        eventType: xEnum.ENUM_RP_EVENT_TYPE.GOODS_DETAIL_PV.value, eventInfo: {
          pId: store.state.appState.pId,
          userId: store.state.user.userId,
          goodsId: goods?.goodsId,
          goodsPrice: goods?.price,
          score: goods?.score,
          goodsGroupId: type,
          webUrl: window.location.href
        }
      })
    },
    async enterSettlement({ commit, state, rootState }, statsInfo) {
      // 优先用带进了的 module参数，如果没有使用缓存的。
      let { module, goods } = statsInfo
      let savedState = loadStatisticsState()

      module = module || savedState.module
      commit('UPDATE_STATISTICS_STATE', {
        key: 'module',
        value: module
      })
      commit('SAVE_STATISTICS_STATE')

      // console.info({
      //   category:`进结算<-${module}`,
      //   action: goods.goodsName,
      // })

      trackEvent({
        category: `进结算<-${module}`,
        action: goods.goodsName
      })
    },
    async lauchPay({ commit, state, rootState }, statsInfo) {
      let savedState = loadStatisticsState()
      let { module } = savedState
      let { payment } = statsInfo

      let payType = '未知支付类型'
      if (payment === xEnum.ENUM_PAYMENT_TYPE.WX.getValue()) {
        if (utils.isInWeixin()) {
          payType = '微信公众号'
        } else {
          payType = '微信H5'
        }
      } else if (payment === xEnum.ENUM_PAYMENT_TYPE.ALI.getValue()) {
        payType = '支付宝H5'
      }

      commit('UPDATE_STATISTICS_STATE', {
        key: 'payType',
        value: payType
      })
      commit('SAVE_STATISTICS_STATE')

      // console.info({
      //   category:`唤起支付<-${module}`,
      //   action: payType,
      // })

      trackEvent({
        category: `唤起支付<-${module}`,
        action: payType
      })
    },
    async paySuccessfully({ commit, state, rootState }, statsInfo) {
      let savedState = loadStatisticsState()
      let { module, payType } = savedState

      // console.info({
      //   category:`支付成功<-${module}`,
      //   action: payType,
      // })

      trackEvent({
        category: `支付成功<-${module}`,
        action: payType
      })
    }
  }
}

export default appState
